import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Layout from './components/Layout/Layout';
import DeveloperPage from './pages/DeveloperPage/DeveloperPage.js';
import DeveloperPageMonaco from './pages/DeveloperPage/DeveloperPageMonaco.js';
import BackupsPage from './pages/BackupsPage/BackupsPage.js';
import LogsPage from './pages/LogsPage/LogsPage.js';
import UserManagementPage from './pages/UserManagementPage/UserManagementPage';
import TestEditor from './components/MonacoEditor/TestEditor';
import { notification } from 'antd';
import { 
  Box, 
  Typography,
  Fade,
  CircularProgress,
  Button
} from '@mui/material';
import { 
  NotListedLocation as NotFoundIcon
} from '@mui/icons-material';
import './App.css';
import { useNavigate } from 'react-router-dom';

// Wrapper component to provide a clean mount/unmount cycle for each page
const PageWrapper = ({ Component, showNotification }) => {
  // Use location as part of key to force remount when location changes
  const location = useLocation();
  return <Component key={location.pathname} showNotification={showNotification} />;
};

// AuthWrapper component to handle protected routes
const AuthWrapper = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  
  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }
  
  if (!isAuthenticated) {
    return <UnauthenticatedView />;
  }
  
  return children;
};

// Component to display when user is not authenticated
const UnauthenticatedView = () => {
  const { loginWithRedirect } = useAuth0();

  const asciiTiger = `
    /\\_/\\  
  ( o.o ) 
  > ^ <
  `;

  const loginMessage = "You need to be logged in to view this content.";

  return (
    <Fade in={true} timeout={1000}>
      <Box 
        sx={{ 
          p: 5, 
          border: '1px dashed grey',
          borderRadius: 2,
          textAlign: 'center',
          backgroundColor: '#f5f5f5',
          maxWidth: '600px',
          margin: '100px auto'
        }}
      >
        <NotFoundIcon color="primary" style={{ fontSize: 60, marginBottom: '20px' }} />
        <Typography variant="h5" gutterBottom>
          {loginMessage}
        </Typography>
        <Typography 
          variant="body1" 
          style={{ 
            fontFamily: 'monospace', 
            whiteSpace: 'pre',
            marginTop: '20px',
            textAlign: 'center'
          }}
        >
          {asciiTiger}
        </Typography>
        
        <Box sx={{ mt: 4 }}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => loginWithRedirect()}
            startIcon={<span role="img" aria-label="login">🔐</span>}
          >
            Login
          </Button>
        </Box>
      </Box>
    </Fade>
  );
};

// Create a dedicated LoginPage component
const LoginPage = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  
  // If user is already authenticated, redirect to logs
  React.useEffect(() => {
    if (isAuthenticated) {
      navigate('/logs');
    }
  }, [isAuthenticated, navigate]);
  
  return (
    <Box 
      sx={{ 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        background: 'linear-gradient(135deg, #1c3a4f 0%, #243b4a 100%)',
      }}
    >
      <Fade in={true} timeout={1000}>
        <Box 
          sx={{ 
            p: 5, 
            borderRadius: 4,
            textAlign: 'center',
            backgroundColor: 'white',
            boxShadow: 3,
            maxWidth: '500px',
            width: '90%'
          }}
        >
          <Box 
            component="img"
            src={`${process.env.PUBLIC_URL}/primary_logo_tgt.png`}
            alt="Teiga Technology Logo"
            sx={{ height: 80, mb: 3 }}
          />
          <Typography variant="h4" gutterBottom sx={{ color: '#1c3a4f' }}>
            FTP-EZ Tool
          </Typography>
          <Typography variant="body1" sx={{ mb: 4, color: '#555' }}>
            Secure file management and tracking solution
          </Typography>
          
          <Button 
            variant="contained" 
            size="large"
            color="primary" 
            onClick={() => loginWithRedirect()}
            sx={{ 
              py: 1.5, 
              px: 4, 
              borderRadius: 2,
              backgroundColor: '#1c3a4f',
              '&:hover': {
                backgroundColor: '#142c3b'
              }
            }}
          >
            Login to Continue
          </Button>
        </Box>
      </Fade>
    </Box>
  );
};

function App() {
  const { isLoading } = useAuth0();
  const [api, contextHolder] = notification.useNotification();

  const showNotification = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
      placement: 'topRight',
      duration: 4.5,
    });
  };

  // Show loading indicator while Auth0 is initializing
  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Router>
      <div className="App">
        {contextHolder}
        
        <Routes>
          {/* Redirect root to login */}
          <Route path="/" element={<Navigate to="/login" replace />} />
          
          {/* Login page - outside of layout */}
          <Route path="/login" element={<LoginPage />} />
          
          {/* Test route for Monaco Editor */}
          <Route path="/test-editor" element={<TestEditor />} />
          
          {/* Wrap all app content pages in AuthWrapper and Layout */}
          <Route path="/logs" element={
            <AuthWrapper>
              <Layout>
                <PageWrapper Component={LogsPage} showNotification={showNotification} />
              </Layout>
            </AuthWrapper>
          } />
          
          <Route path="/developer" element={
            <AuthWrapper>
              <Layout>
                <PageWrapper Component={DeveloperPageMonaco} showNotification={showNotification} />
              </Layout>
            </AuthWrapper>
          } />
          
          {/* New route for Monaco-based DeveloperPage */}
          <Route path="/developer-monaco" element={
            <AuthWrapper>
              <Navigate to="/developer" replace />
            </AuthWrapper>
          } />
          
          <Route path="/backups" element={
            <AuthWrapper>
              <Layout>
                <PageWrapper Component={BackupsPage} showNotification={showNotification} />
              </Layout>
            </AuthWrapper>
          } />
          
          <Route path="/users" element={
            <AuthWrapper>
              <Layout>
                <PageWrapper Component={UserManagementPage} showNotification={showNotification} />
              </Layout>
            </AuthWrapper>
          } />
          
          {/* Redirect from editor page to developer page */}
          <Route path="/editor" element={
            <AuthWrapper>
              <Navigate to="/developer" replace />
            </AuthWrapper>
          } />
          
          {/* 404 Page - Must be last */}
          <Route path="*" element={
            <Box 
              sx={{ 
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                textAlign: 'center',
                p: 3
              }}
            >
              <NotFoundIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
              <Typography variant="h4" gutterBottom>
                Page Not Found
              </Typography>
              <Typography variant="body1" sx={{ mb: 3 }}>
                The page you're looking for doesn't exist or has been moved.
              </Typography>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={() => window.location.href = '/logs'}
              >
                Go to Logs
              </Button>
            </Box>
          } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
