import React, { useState, useEffect } from 'react';
import { Table, Input } from 'antd';
import { Resizable } from 're-resizable';
import { 
  Box, 
  Typography, 
  Paper, 
  Chip, 
  Button,
  IconButton, 
  TextField,
  Breadcrumbs,
  Link,
  Tooltip,
  LinearProgress,
  InputAdornment
} from '@mui/material';
import {
  Folder as FolderIcon,
  FolderOpen as FolderOpenIcon,
  Description as FileIcon,
  Image as ImageIcon,
  Code as CodeIcon,
  PictureAsPdf as PdfIcon,
  Archive as ArchiveIcon,
  InsertDriveFile as DocumentIcon,
  TextSnippet as TextIcon,
  TableChart as SpreadsheetIcon,
  CloudDownload as DownloadIcon,
  Search as SearchIcon,
  Refresh as RefreshIcon,
  ArrowUpward as NavigateUpIcon,
  Home as HomeIcon
} from '@mui/icons-material';
import './BackupsPage.css';

const { Search: AntSearch } = Input;

const BackupsPage = ({ showNotification }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPath, setCurrentPath] = useState('2025test');
  const [searchTerm, setSearchTerm] = useState('');
  const [pathHistory, setPathHistory] = useState([{ name: 'Root', path: '' }, { name: '2025test', path: '2025test' }]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0
  });
  const [sortConfig, setSortConfig] = useState({
    key: 'isDirectory',
    order: 'descend' // Start with folders first
  });

  const fetchFiles = async (path) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/listBlobFiles?path=${encodeURIComponent(path)}`
      );
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      
      if (data.success) {
        // Sort files with folders first by default
        const sortedFiles = [...(data.files || [])].sort((a, b) => {
          if (a.isDirectory === b.isDirectory) {
            return a.name.localeCompare(b.name);
          }
          return a.isDirectory ? -1 : 1;
        });
        setFiles(sortedFiles);
      } else {
        showNotification('error', 'Error', data.message || 'Failed to fetch files');
        setFiles([]);
      }
    } catch (error) {
      console.error('Error fetching files:', error);
      showNotification('error', 'Error', 'Failed to fetch files from storage');
      setFiles([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Initialize with loading files from the 2025test folder
    fetchFiles('2025test');
    
    // Initialize path history with 2025test as the starting point
    setPathHistory([{ name: 'Root', path: '' }, { name: '2025test', path: '2025test' }]);
  }, []);

  const handleTableChange = (pagination, filters, sorter) => {
    setSortConfig({
      key: sorter.field,
      order: sorter.order
    });
  };

  const getSortedFiles = (fileList) => {
    const sortedFiles = [...fileList];
    
    // First, separate folders and files
    const folders = sortedFiles.filter(item => item.isDirectory);
    const files = sortedFiles.filter(item => !item.isDirectory);
    
    // Sort folders and files separately based on the current sort config
    if (sortConfig.key === 'lastModified') {
      const sortFn = (a, b) => {
        const dateA = new Date(a.lastModified);
        const dateB = new Date(b.lastModified);
        return sortConfig.order === 'ascend' ? dateA - dateB : dateB - dateA;
      };
      folders.sort(sortFn);
      files.sort(sortFn);
    } else if (sortConfig.key === 'name') {
      const sortFn = (a, b) => {
        return sortConfig.order === 'ascend' 
          ? a.name.localeCompare(b.name) 
          : b.name.localeCompare(a.name);
      };
      folders.sort(sortFn);
      files.sort(sortFn);
    } else if (sortConfig.key === 'size') {
      const sortFn = (a, b) => {
        const sizeA = a.size || 0;
        const sizeB = b.size || 0;
        return sortConfig.order === 'ascend' ? sizeA - sizeB : sizeB - sizeA;
      };
      folders.sort(sortFn);
      files.sort(sortFn);
    }
    
    // Combine folders and files, with folders always first
    return [...folders, ...files];
  };

  const handleDownload = async (blobPath) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/downloadBlob?path=${encodeURIComponent(blobPath)}`
      );
      
      if (!response.ok) {
        throw new Error('Download failed');
      }
      
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = blobPath.split('/').pop();
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      
      showNotification('success', 'Success', 'File downloaded successfully');
    } catch (error) {
      console.error('Download error:', error);
      showNotification('error', 'Error', 'Failed to download file');
    }
  };

  const getFileIcon = (record) => {
    if (record.isDirectory) {
      return <FolderIcon className="file-icon folder-icon" />;
    }
    
    const extension = record.name.split('.').pop().toLowerCase();
    
    switch(extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'svg':
      case 'webp':
        return <ImageIcon className="file-icon image-icon" />;
      case 'pdf':
        return <PdfIcon className="file-icon pdf-icon" />;
      case 'zip':
      case 'rar':
      case '7z':
      case 'tar':
      case 'gz':
        return <ArchiveIcon className="file-icon archive-icon" />;
      case 'doc':
      case 'docx':
        return <DocumentIcon className="file-icon document-icon" />;
      case 'xls':
      case 'xlsx':
      case 'csv':
        return <SpreadsheetIcon className="file-icon spreadsheet-icon" />;
      case 'txt':
      case 'md':
        return <TextIcon className="file-icon text-icon" />;
      case 'html':
      case 'htm':
      case 'css':
      case 'js':
      case 'json':
      case 'xml':
      case 'php':
        return <CodeIcon className="file-icon code-icon" />;
      default:
        return <FileIcon className="file-icon default-icon" />;
    }
  };

  const getFileTypeChip = (record) => {
    if (record.isDirectory) {
      return <Chip 
        label="Folder" 
        size="small" 
        icon={<FolderIcon />} 
        className="file-type-chip folder-chip"
      />;
    }
    
    const extension = record.name.split('.').pop().toLowerCase();
    const typeMap = {
      'htm': { label: 'HTML', className: 'html-chip', icon: <CodeIcon /> },
      'html': { label: 'HTML', className: 'html-chip', icon: <CodeIcon /> },
      'css': { label: 'CSS', className: 'css-chip', icon: <CodeIcon /> },
      'js': { label: 'JavaScript', className: 'js-chip', icon: <CodeIcon /> },
      'json': { label: 'JSON', className: 'js-chip', icon: <CodeIcon /> },
      'jpg': { label: 'Image', className: 'image-chip', icon: <ImageIcon /> },
      'jpeg': { label: 'Image', className: 'image-chip', icon: <ImageIcon /> },
      'png': { label: 'Image', className: 'image-chip', icon: <ImageIcon /> },
      'gif': { label: 'Image', className: 'image-chip', icon: <ImageIcon /> },
      'svg': { label: 'SVG', className: 'image-chip', icon: <ImageIcon /> },
      'pdf': { label: 'PDF', className: 'pdf-chip', icon: <PdfIcon /> },
      'doc': { label: 'Word', className: 'doc-chip', icon: <DocumentIcon /> },
      'docx': { label: 'Word', className: 'doc-chip', icon: <DocumentIcon /> },
      'xls': { label: 'Excel', className: 'excel-chip', icon: <SpreadsheetIcon /> },
      'xlsx': { label: 'Excel', className: 'excel-chip', icon: <SpreadsheetIcon /> },
      'csv': { label: 'CSV', className: 'excel-chip', icon: <SpreadsheetIcon /> },
      'txt': { label: 'Text', className: 'text-chip', icon: <TextIcon /> },
      'md': { label: 'Markdown', className: 'text-chip', icon: <TextIcon /> },
      'zip': { label: 'ZIP', className: 'archive-chip', icon: <ArchiveIcon /> },
      'rar': { label: 'RAR', className: 'archive-chip', icon: <ArchiveIcon /> },
      '7z': { label: '7Z', className: 'archive-chip', icon: <ArchiveIcon /> }
    };
    
    const fileType = typeMap[extension] || { 
      label: extension && typeof extension === 'string' ? extension.toUpperCase() : 'File', 
      className: 'other-chip',
      icon: <FileIcon />
    };
    
    return (
      <Chip 
        label={fileType.label} 
        size="small" 
        icon={fileType.icon}
        className={`file-type-chip ${fileType.className}`}
      />
    );
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          '&:hover .download-action': { opacity: 1 } 
        }}>
          {getFileIcon(record)}
          <Box sx={{ ml: 1 }}>
            <Typography variant="body2" className={record.isDirectory ? 'directory-name' : 'file-name'}>
              {text}
            </Typography>
            {!record.isDirectory && 
              <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
                {typeof record.name === 'string' && record.name.includes('.') ? 
                  record.name.split('.').pop().toUpperCase() : 
                  'FILE'}
              </Typography>
            }
          </Box>
          {!record.isDirectory && (
            <IconButton 
              size="small" 
              className="download-action"
              onClick={(e) => {
                e.stopPropagation();
                handleDownload(record.path);
              }}
              sx={{ 
                ml: 'auto', 
                opacity: 0,
                transition: 'opacity 0.2s ease-in-out',
              }}
            >
              <DownloadIcon fontSize="small" color="primary" />
            </IconButton>
          )}
        </Box>
      ),
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Type',
      dataIndex: 'fileType',
      key: 'fileType',
      render: (_, record) => getFileTypeChip(record),
      filters: [
        { text: 'Folder', value: 'Folder' },
        { text: 'HTML', value: 'HTML' },
        { text: 'CSS', value: 'CSS' },
        { text: 'JavaScript', value: 'JavaScript' },
        { text: 'Image', value: 'Image' },
        { text: 'Document', value: 'Document' },
        { text: 'PDF', value: 'PDF' },
        { text: 'Archive', value: 'Archive' },
        { text: 'Other', value: 'Other' }
      ],
      onFilter: (value, record) => {
        if (value === 'Folder') return record.isDirectory;
        
        const extension = record.name.split('.').pop().toLowerCase();
        if (value === 'HTML') return extension === 'htm' || extension === 'html';
        if (value === 'CSS') return extension === 'css';
        if (value === 'JavaScript') return extension === 'js' || extension === 'json';
        if (value === 'Image') return ['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp'].includes(extension);
        if (value === 'Document') return ['doc', 'docx', 'txt', 'md'].includes(extension);
        if (value === 'PDF') return extension === 'pdf';
        if (value === 'Archive') return ['zip', 'rar', '7z', 'tar', 'gz'].includes(extension);
        
        return !record.isDirectory && !['html', 'htm', 'css', 'js', 'json', 'jpg', 'jpeg', 'png', 'gif', 'svg', 'webp', 'pdf', 'doc', 'docx', 'txt', 'md', 'zip', 'rar', '7z'].includes(extension);
      }
    },
    {
      title: 'Last Modified',
      dataIndex: 'lastModified',
      key: 'lastModified',
      render: (date) => {
        const formattedDate = new Date(date).toLocaleDateString(undefined, {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        });
        const formattedTime = new Date(date).toLocaleTimeString(undefined, {
          hour: '2-digit',
          minute: '2-digit'
        });
        
        return (
          <Box>
            <Typography variant="body2">{formattedDate}</Typography>
            <Typography variant="caption" color="text.secondary">{formattedTime}</Typography>
          </Box>
        );
      },
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      defaultSortOrder: 'descend',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      render: (size) => {
        if (!size) return <Typography variant="body2">-</Typography>;
        
        const units = ['B', 'KB', 'MB', 'GB'];
        let i = 0;
        let fileSize = size;
        
        while (fileSize >= 1024 && i < units.length - 1) {
          fileSize /= 1024;
          i++;
        }
        
        return (
          <Typography 
            variant="body2" 
            sx={{ 
              color: i >= 2 ? '#ff5722' : (i === 1 ? '#ff9800' : 'inherit'),
              fontWeight: i >= 1 ? 500 : 400
            }}
          >
            {`${fileSize.toFixed(2)} ${units[i]}`}
          </Typography>
        );
      },
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button
          variant="outlined"
          size="small"
          startIcon={<DownloadIcon />}
          onClick={() => handleDownload(record.path)}
          disabled={record.isDirectory}
          sx={{ 
            borderColor: 'rgba(0, 184, 212, 0.5)',
            color: '#00b8d4',
            '&:hover': {
              borderColor: '#00b8d4',
              backgroundColor: 'rgba(0, 184, 212, 0.04)'
            }
          }}
        >
          Download
        </Button>
      ),
    },
  ];

  const filteredFiles = getSortedFiles(
    files.filter(file => file.name.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  // Add a utility function to help format breadcrumb paths
  const getBreadcrumbName = (path) => {
    if (!path) return 'Root';
    if (path === '2025test') return '2025test';
    
    // Extract the last path segment for display
    const segments = path.split('/').filter(s => s);
    if (segments.length === 0) return 'Root';
    
    return segments[segments.length - 1];
  };
  
  const handleFolderClick = (path) => {
    // Update path history when navigating to a new folder
    const newPathName = getBreadcrumbName(path);
    const newPath = { name: newPathName, path: path };
    
    // Find if this path is already in history (going back)
    const existingIndex = pathHistory.findIndex(item => item.path === path);
    
    if (existingIndex >= 0) {
      // If going back, truncate the history at this point
      setPathHistory(pathHistory.slice(0, existingIndex + 1));
    } else {
      // If going forward to a new location, add to history
      setPathHistory([...pathHistory, newPath]);
    }
    
    setCurrentPath(path);
    fetchFiles(path);
  };

  return (
    <Box className="backups-container">
      <Paper elevation={0} sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', borderRadius: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h4" sx={{ 
            fontWeight: 600, 
            color: '#424242',
            display: 'flex',
            alignItems: 'center' 
          }}>
            <ArchiveIcon sx={{ mr: 1, color: '#00b8d4' }} /> Backup Files
          </Typography>
          
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title="Refresh">
              <IconButton 
                onClick={() => fetchFiles(currentPath)}
                color="primary"
                sx={{ mr: 1 }}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
            <TextField
              placeholder="Search files..."
              onChange={e => setSearchTerm(e.target.value)}
              size="small"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                ),
              }}
              sx={{
                width: 250,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'rgba(0, 184, 212, 0.3)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(0, 184, 212, 0.5)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#00b8d4',
                  },
                },
              }}
            />
          </Box>
        </Box>
        
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Tooltip title="Home">
            <IconButton 
              onClick={() => handleFolderClick('2025test')}
              color="primary"
              size="small"
              sx={{ mr: 1 }}
            >
              <HomeIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          
          <Tooltip title="Up one level">
            <span>
              <IconButton 
                onClick={() => handleFolderClick(pathHistory[pathHistory.length - 2].path)}
                color="primary"
                size="small"
                disabled={pathHistory.length <= 1}
                sx={{ mr: 1 }}
              >
                <NavigateUpIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
          
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {pathHistory.map((item, index) => (
              <Link
                key={index}
                component="button"
                variant="body2"
                onClick={() => {
                  if (index < pathHistory.length - 1) {
                    handleFolderClick(item.path);
                  }
                }}
                sx={{ 
                  color: index === pathHistory.length - 1 ? '#00b8d4' : 'text.primary',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {index === 0 ? (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <HomeIcon fontSize="small" sx={{ mr: 0.5 }} />
                    {item.name}
                  </Box>
                ) : (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FolderOpenIcon fontSize="small" sx={{ mr: 0.5 }} />
                    {item.name}
                  </Box>
                )}
              </Link>
            ))}
          </Breadcrumbs>
        </Box>

        {loading && (
          <Box sx={{ width: '100%', mb: 2 }}>
            <LinearProgress color="primary" />
          </Box>
        )}

        <Box sx={{ flex: 1, overflow: 'auto' }}>
          <Table
            className="backups-table"
            columns={columns}
            dataSource={filteredFiles}
            loading={loading}
            rowKey="path"
            pagination={{ 
              pageSize: 20,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '50', '100'],
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
            }}
            onChange={handleTableChange}
            onRow={(record) => ({
              onDoubleClick: () => {
                if (record.isDirectory) {
                  const newPath = record.path;
                  handleFolderClick(newPath);
                }
              },
              style: { 
                cursor: record.isDirectory ? 'pointer' : 'default',
              }
            })}
            locale={{
              emptyText: (
                <Box sx={{ py: 5, textAlign: 'center' }}>
                  <FolderOpenIcon sx={{ fontSize: 48, color: 'rgba(0, 184, 212, 0.3)', mb: 1 }} />
                  <Typography variant="body1" color="text.secondary">
                    No files found in this location
                  </Typography>
                  {searchTerm && (
                    <Typography variant="body2" color="text.secondary">
                      Try a different search term
                    </Typography>
                  )}
                </Box>
              )
            }}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default BackupsPage; 