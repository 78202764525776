import React, { useEffect, useRef } from 'react';
import { Editor } from '@monaco-editor/react';
import { Button } from 'antd';

const MonacoEditor = ({ content, onContentChange }) => {
  const editorRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    // Force layout calculation when component mounts
    if (editorRef.current) {
      setTimeout(() => {
        editorRef.current.layout();
      }, 100);
    }

    // Ensure Monaco editor is properly sized on window resize
    const handleResize = () => {
      if (editorRef.current) {
        editorRef.current.layout();
      }
    };

    window.addEventListener('resize', handleResize);
    
    // Clean up
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Handler for when the editor mounts
  const handleEditorDidMount = (editor, monaco) => {
    editorRef.current = editor;
    
    // Focus the editor once it's mounted to encourage interaction
    setTimeout(() => {
      if (editor) {
        // Force the editor to receive focus
        editor.focus();
        
        // Add custom commands for find functionality - using the built-in find widget
        editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyF, () => {
          // Get the find controller
          const findController = editor.getContribution('editor.contrib.findController');
          if (findController) {
            // Show the find widget with default settings
            findController.start({
              forceRevealReplace: false,
              seedSearchStringFromSelection: 'selection',
              shouldFocus: 'find',
              shouldAnimate: true
            });
          }
        });
        
        // Make sure the DOM element is interactive
        const editorDomNode = editor.getDomNode();
        if (editorDomNode) {
          // Apply styles directly to ensure editor is interactive
          editorDomNode.style.pointerEvents = 'auto';
          
          // Force editor to refresh its layout
          editor.layout();
        }
      }
    }, 300);
  };

  // Format HTML using Monaco's built-in formatter
  const formatHtml = () => {
    if (editorRef.current) {
      editorRef.current.getAction('editor.action.formatDocument').run();
    }
  };

  return (
    <div 
      className="code-editor-container" 
      ref={containerRef}
      style={{ 
        border: '1px solid #ccc', 
        borderRadius: '4px',
        marginBottom: '20px',
        backgroundColor: '#f5f5f5',
        height: '60vh',
        position: 'relative',
        zIndex: 5,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div style={{ 
        padding: '8px', 
        backgroundColor: '#f8f9fa', 
        borderBottom: '1px solid #e0e0e0', 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <div>
          <Button 
            onClick={formatHtml} 
            size="small" 
            type="primary"
            style={{
              backgroundColor: '#1890ff',
              borderColor: '#1890ff',
              borderRadius: '3px',
              padding: '0 12px',
              height: '28px',
              fontSize: '13px'
            }}
          >
            Format HTML
          </Button>
        </div>
      </div>
      <div style={{ 
        flex: 1,
        position: 'relative',
        zIndex: 5,
        pointerEvents: 'auto',
        overflow: 'visible'
      }}>
        <Editor
          height="100%"
          width="100%"
          defaultLanguage="html"
          value={content || ''}
          onChange={onContentChange}
          onMount={handleEditorDidMount}
          className="monaco-editor-instance"
          beforeMount={(monaco) => {
            // Configure Monaco editor's find widget
            monaco.editor.defineTheme('customTheme', {
              base: 'vs',
              inherit: true,
              rules: [],
              colors: {
                // Proper colors for find widget
                'editorWidget.background': '#f5f5f5',
                'editorWidget.border': '#cccccc',
                'editor.findMatchBackground': '#ffff0080',
                'editor.findMatchHighlightBackground': '#ffff0080',
                'editorWidget.resizeBorder': '#cccccc',
                'editorWidget.foreground': '#000000',
                'editorHoverWidget.foreground': '#000000',
                'editorSuggestWidget.foreground': '#000000'
              }
            });
            
            // Disable HTML validation and suggestions but keep syntax highlighting
            if (monaco.languages.html) {
              monaco.languages.html.htmlDefaults.setOptions({
                format: {
                  tabSize: 2,
                  insertSpaces: true,
                },
                suggest: false,
                validate: false,
                hover: false,
                autoClosingTags: false
              });
            }
          }}
          theme="customTheme"
          options={{
            minimap: { 
              enabled: true,
              showSlider: "always",
              renderCharacters: false,
              maxColumn: 120,
              scale: 1,
              side: "right"
            },
            fontSize: 14,
            fontFamily: "'Consolas', 'Menlo', 'Monaco', 'Courier New', monospace",
            fontLigatures: true,
            lineNumbers: 'on',
            scrollBeyondLastLine: false,
            wordWrap: 'on',
            automaticLayout: true,
            fixedOverflowWidgets: true,
            folding: true,
            lineDecorationsWidth: 10,
            lineNumbersMinChars: 3,
            renderControlCharacters: true,
            renderWhitespace: 'boundary',
            readOnly: false,
            domReadOnly: false,
            // Disable suggestions and intellisense
            quickSuggestions: false,
            suggestOnTriggerCharacters: false,
            acceptSuggestionOnEnter: "off",
            tabCompletion: "off",
            wordBasedSuggestions: false,
            parameterHints: { enabled: false },
            // Configure find widget
            find: {
              addExtraSpaceOnTop: true,
              seedSearchStringFromSelection: 'selection',
              autoFindInSelection: false,
              globalFindClipboard: false
            },
            // Disable suggestions
            suggest: { 
              enabled: false,
              showIcons: false,
              showStatusBar: false,
              preview: false
            },
            // Enable better HTML syntax highlighting
            colorDecorators: true
          }}
        />
      </div>
    </div>
  );
};

export default MonacoEditor; 