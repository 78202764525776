import { createTheme } from '@mui/material/styles';

// Create a custom theme based on the provided Tailwind color scheme
const theme = createTheme({
  palette: {
    primary: {
      main: '#1C3A4F',  // Prussian blue
      light: '#31658b',
      dark: '#112330',
    },
    secondary: {
      main: '#738786',  // Battleship gray
      light: '#8f9f9e',
      dark: '#5c6c6b',
    },
    background: {
      default: '#f5f5f5',
      paper: '#ffffff',
    },
    error: {
      main: '#d32f2f',
    },
    warning: {
      main: '#ffa000',
    },
    info: {
      main: '#31658b', // Lighter Prussian blue
    },
    success: {
      main: '#388e3c',
    },
    gunmetal: {
      main: '#242B30',
      light: '#48565f',
      dark: '#15191c',
    },
    text: {
      primary: '#242B30', // Gunmetal
      secondary: '#738786', // Battleship gray
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    button: {
      textTransform: 'none',
      fontWeight: 500,
    },
    h6: {
      fontWeight: 500,
      color: '#f5f5f5',
    }
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          textTransform: 'none',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2)',
          },
        },
        contained: {
          background: 'linear-gradient(135deg, #1C3A4F 0%, #112330 100%)',
          '&:hover': {
            background: 'linear-gradient(135deg, #31658b 0%, #1C3A4F 100%)',
          }
        },
        outlined: {
          borderColor: '#1C3A4F',
          '&:hover': {
            borderColor: '#31658b',
            backgroundColor: 'rgba(28, 58, 79, 0.04)',
          }
        }
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'rgba(28, 58, 79, 0.04)',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 8,
        },
        elevation1: {
          boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.05)',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: '#242B30', // Gunmetal
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: 'rgba(115, 135, 134, 0.2)', // Battleship gray with transparency
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: '#738786', // Battleship gray
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#242B30', // Gunmetal
          color: '#f5f5f5',
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: '#f5f5f5',
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#f5f5f5',
        }
      }
    }
  },
});

export default theme; 