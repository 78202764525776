import React, { useState, useEffect } from 'react';
import FileControlsDeveloper from '../../components/FileControlsDeveloper/FileControlsDeveloper';
import DeveloperEditor from '../../components/DeveloperEditor/developerEditor';
// import PdfControls from '../../components/PdfControls/PdfControls';
import CustomUpload from '../../components/CustomUpload/CustomUpload';
import { useAuth0 } from '@auth0/auth0-react';
import FtpBrowser from '../../components/FtpBrowser/FtpBrowser';
import RollbackModal from '../../components/RollbackModal/RollbackModal';
import { Modal, Button, Table, Tag, Alert, Spin } from 'antd';
import { FolderOutlined, CheckCircleOutlined, CloseCircleOutlined, WarningOutlined } from '@ant-design/icons';
const beautifyHtml = require('js-beautify').html;

const DeveloperPage = ({ showNotification }) => {
  const [filePath, setFilePath] = useState('');
  const [originalHtmlContent, setOriginalHtmlContent] = useState('');
  const [editedHtmlContent, setEditedHtmlContent] = useState('');
  const [isLocked, setIsLocked] = useState(false);
  const [lockHolder, setLockHolder] = useState(null);
  const [lockHolderEmail, setLockHolderEmail] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isRollbackModalVisible, setIsRollbackModalVisible] = useState(false);
  const [initialLoadContent, setInitialLoadContent] = useState('');
  const [savedFileMessage, setSavedFileMessage] = useState(null);
  const [isFtpBrowserVisible, setIsFtpBrowserVisible] = useState(false);
  const [isLockModalVisible, setIsLockModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidatorVisible, setIsValidatorVisible] = useState(false);
  const [validationResults, setValidationResults] = useState([]);
  const [validationStatus, setValidationStatus] = useState(null); // 'success', 'warning', 'error'
  const [isValidating, setIsValidating] = useState(false);
  const [fileLoadTime, setFileLoadTime] = useState(null); // Track when file was loaded
  const { user } = useAuth0();

  const handleFileChange = (newPath) => {
    setFilePath(newPath);
  };

  const handleLoadNewFile = async () => {
    // Clear previous state
    setOriginalHtmlContent('');
    setEditedHtmlContent('');
    setErrorMessage(null);
    setIsLoading(true);
    
    // Reset lock state at the beginning
    setIsLocked(false);
    setLockHolder(null);
    setLockHolderEmail(null);
    
    try {
      // Validate required parameters before proceeding
      if (!filePath) {
        setErrorMessage('File path is required');
        setIsLoading(false);
        return;
      }
      
      if (!user || !user.sub || !user.email) {
        setErrorMessage('User information is missing. Please ensure you are logged in.');
        setIsLoading(false);
        return;
      }
      
      // Format the path correctly
      const formattedFilePath = filePath.trim();
      
      // First check if the file is locked
      console.log("Checking if file is locked:", formattedFilePath);
      const lockCheckResponse = await fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/checkLock?path=${encodeURIComponent(formattedFilePath)}`);
      const lockCheckData = await lockCheckResponse.json();
      console.log("Lock check response:", lockCheckData);
      
      // Always try to acquire the lock, even if it's already locked by this user
      console.log("Attempting to acquire lock");
      const lockResponse = await fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/acquireLock`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          path: formattedFilePath,
          userId: user.sub,
          userEmail: user.email
        })
      });
      
      const lockData = await lockResponse.json();
      console.log("Lock acquisition response:", lockData);
      
      if (!lockData.success) {
        // Only show an error for the lock if it's locked by someone else
        if (lockData.lock && lockData.lock.userId !== user.sub) {
          console.log("Lock acquisition failed - file locked by another user:", lockData.lock.userEmail);
          setIsLocked(true);
          setLockHolder(lockData.lock.userId);
          setLockHolderEmail(lockData.lock.userEmail);
          setErrorMessage(`File is locked by ${lockData.lock.userEmail}`);
          setIsLoading(false);
          return;
        } else if (!lockData.message?.includes('refreshed')) {
          // Show non-lock related errors
          console.log("Lock acquisition failed with message:", lockData.message);
          setErrorMessage(`Failed to lock file: ${lockData.message}`);
          setIsLoading(false);
          return;
        }
      }
      
      // If we got here, we have the lock
      console.log("Lock acquired successfully");
      setIsLocked(true);
      setLockHolder(user.sub);
      setLockHolderEmail(user.email);
      
      // Now download the file
      console.log("Downloading file content");
      const downloadResponse = await fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/triggerDownload?path=${encodeURIComponent(formattedFilePath)}`);
      
      if (!downloadResponse.ok) {
        // Release the lock if download fails
        console.log("Download failed with HTTP error");
        await releaseLock(formattedFilePath);
        setIsLocked(false);  // Make sure to update the UI state
        setLockHolder(null);
        setLockHolderEmail(null);
        throw new Error(`HTTP error! Status: ${downloadResponse.status}`);
      }
      
      const data = await downloadResponse.json();
      console.log("Download response:", data);
      
      if (data.success) {
        // Save the received content
        console.log("File content downloaded successfully");
        setInitialLoadContent(data.fileContent);
        setOriginalHtmlContent(data.fileContent);
        setEditedHtmlContent(data.fileContent);
        
        // Clear any error message
        setErrorMessage(null);
        
        // Initialize file load time for editing time tracking
        setFileLoadTime(Date.now());
        
        // Set success state 
        setIsLoading(false);
        // Success notification 
        showNotification('success', 'Success', 'File loaded and locked successfully');
      } else {
        // Release the lock if download fails
        console.log("Download failed, releasing lock");
        await releaseLock(formattedFilePath);
        
        // Clear the lock state in the UI
        setIsLocked(false);
        setLockHolder(null);
        setLockHolderEmail(null);
        
        // Show download error
        console.log("General download error:", data.message);
        setErrorMessage(`Error loading file: ${data.message}`);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error loading file:', error);
      setErrorMessage(`Error: ${error.message}`);
      setIsLoading(false);
      
      // Try to release the lock if we had acquired it
      if (isLocked && lockHolder === user.sub) {
        console.log("Releasing lock after error");
        try {
          await releaseLock(filePath);
          // Update the UI state
          setIsLocked(false);
          setLockHolder(null);
          setLockHolderEmail(null);
        } catch (releaseLockError) {
          console.error('Error releasing lock after failed download:', releaseLockError);
        }
      }
    }
  };

  const handleContentChange = (newContent) => {
    setEditedHtmlContent(newContent);
  };

  const saveContentEdits = async () => {
    const REACT_APP_BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
    
    console.log("saveContentEdits - Starting save process");
    
    // Clear any previous save message
    setSavedFileMessage(null);

    if (!filePath) {
      alert('File path is not set.');
      console.log("saveContentEdits - Error: No file path set");
      return;
    }

    // Check if locked based on current state
    if (isLocked && lockHolder !== user.sub) {
      alert('Cannot save: file is locked by another user. Please use Force Unlock first.');
      console.log(`saveContentEdits - Error: File locked by ${lockHolderEmail}`);
      return;
    }

    console.log("saveContentEdits - Verifying lock status with server");
    // Perform a real-time check with the server to verify lock status
    try {
      const lockCheckResponse = await fetch(`${REACT_APP_BACKEND_ENDPOINT}/checkLock?path=${encodeURIComponent(filePath)}`);
      const lockCheckData = await lockCheckResponse.json();
      
      console.log("saveContentEdits - Lock check response:", lockCheckData);
      
      if (lockCheckData.lock && lockCheckData.lock.userId !== user.sub) {
        // Update state to reflect current lock status
        setIsLocked(true);
        setLockHolder(lockCheckData.lock.userId);
        setLockHolderEmail(lockCheckData.lock.userEmail);
        setErrorMessage(`File is currently locked by ${lockCheckData.lock.userEmail}`);
        
        // Notify user
        alert(`Cannot save: file is now locked by ${lockCheckData.lock.userEmail}. Please use Force Unlock first.`);
        showNotification('error', 'Save Failed', `File is locked by ${lockCheckData.lock.userEmail}`);
        return;
      }
      
      // If we get here, either there's no lock, or the file is locked by the current user
      if (lockCheckData.lock) {
        console.log(`saveContentEdits - File is locked by current user (${user.sub})`);
      } else {
        console.log("saveContentEdits - File is not locked - will attempt to save anyway");
        // If file isn't locked but should be, try to acquire a lock
        if (user?.sub && user?.email) {
          console.log("saveContentEdits - Acquiring lock before saving");
          const lockResponse = await fetch(`${REACT_APP_BACKEND_ENDPOINT}/acquireLock`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              path: filePath,
              userId: user.sub,
              userEmail: user.email
            })
          });
          const lockData = await lockResponse.json();
          console.log("saveContentEdits - Lock acquisition result:", lockData);
        }
      }
    } catch (error) {
      console.error('Error checking lock status:', error);
      // Continue with save attempt even if lock check fails
    }

    // Calculate editing time in milliseconds (time from loading to saving)
    const editingTime = fileLoadTime ? Date.now() - fileLoadTime : 0;
    console.log("saveContentEdits - Editing time:", editingTime, "ms");

    try {
      console.log("saveContentEdits - Sending save request to server");
      console.log("saveContentEdits - Content length:", editedHtmlContent.length, "characters");
      
      // Start saving spinner
      setIsLoading(true);
      
      const response = await fetch(`${REACT_APP_BACKEND_ENDPOINT}/saveEditedFile?path=${encodeURIComponent(filePath)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-User-ID': user?.sub || '',
          'X-User-Email': user?.email || '',
          'X-User-Name': user?.name || '',
          'X-Tool-Used': 'DeveloperPage',
          'X-Editing-Time': editingTime.toString()
        },
        body: JSON.stringify({ originalContent: originalHtmlContent, editedContent: editedHtmlContent })
      });
      
      console.log("saveContentEdits - Save response status:", response.status);
      
      if (!response.ok) {
        throw new Error(`Server returned ${response.status}: ${response.statusText}`);
      }
      
      const data = await response.json();
      console.log("saveContentEdits - Save response data:", data);
      
      // End saving spinner
      setIsLoading(false);
      
      if (data.success) {
        // Update the original content
        setOriginalHtmlContent(editedHtmlContent);
        
        // Release the lock
        console.log("saveContentEdits - File saved. Releasing lock");
        await releaseLock(filePath);
        
        // Show success notification
        showNotification('success', 'Success', 'File saved and lock released');
        
        // Reset editing time tracking by setting a new file load time
        setFileLoadTime(Date.now());
        
        // Set the saved file message with more details
        setSavedFileMessage(`Successfully saved changes to: ${filePath}`);
        
        // Auto-clear the message after 10 seconds
        setTimeout(() => {
          setSavedFileMessage(null);
        }, 10000);
      } else {
        console.error("saveContentEdits - Save failed:", data.message);
        alert('Error saving file: ' + data.message);
      }
    } catch (error) {
      console.error('Error saving edits:', error);
      setIsLoading(false);
      alert('Error saving edits: ' + error.message);
    }
  };

  const checkFileLock = async (path) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/checkLock?path=${path}`);
      const data = await response.json();
      if (data.lock) {
        setIsLocked(true);
        setLockHolder(data.lock.userId);
        
        showNotification(
          'warning',
          'File Locked',
          <div>
            This file is currently locked by another user.
            <button 
              onClick={() => handleForceUnlock(path)}
              style={{
                marginLeft: '10px',
                padding: '5px 10px',
                backgroundColor: '#ff4d4f',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer'
              }}
            >
              Force Unlock
            </button>
          </div>,
          0
        );
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error checking lock:', error);
      return false;
    }
  };

  const handleForceUnlock = async (path) => {
    try {
      const lockCheckResponse = await fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/checkLock?path=${path}`);
      const lockData = await lockCheckResponse.json();
      
      if (!lockData.lock?.userId) {
        showNotification('error', 'Error', 'Could not determine lock holder');
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/releaseLock`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          path: path,
          userId: lockData.lock.userId
        })
      });
      
      if (response.ok) {
        setIsLocked(false);
        setLockHolder(null);
        showNotification(
          'success', 
          'Success', 
          <div>
            Lock has been removed. 
            <button 
              onClick={() => handleLoadNewFile()}
              style={{
                marginLeft: '10px',
                padding: '5px 10px',
                backgroundColor: '#1890ff',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer'
              }}
            >
              Edit File
            </button>
          </div>,
          0
        );
      } else {
        showNotification('error', 'Error', 'Failed to force unlock the file');
      }
    } catch (error) {
      console.error('Error forcing unlock:', error);
      showNotification('error', 'Error', 'Failed to force unlock the file');
    }
  };

  const acquireLock = async (path) => {
    try {
      // Convert path to string if it's an object
      const pathString = typeof path === 'object' ? path.toString() : path;
      
      if (!pathString || !user || !user.sub || !user.email) {
        setErrorMessage('File path, user ID, and user email are required for locking');
        return false;
      }
      
      console.log('Attempting to acquire lock for path:', pathString, 'user ID:', user.sub, 'email:', user.email);
      
      // First check if file is already locked
      const initialLockCheck = await fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/checkLock?path=${encodeURIComponent(pathString)}`);
      const initialLockData = await initialLockCheck.json();
      console.log('Initial lock check:', initialLockData);

      if (initialLockData.lock) {
        setIsLocked(true);
        setLockHolder(initialLockData.lock.userId);
        setLockHolderEmail(initialLockData.lock.userEmail);
        setErrorMessage(`This file is currently locked by ${initialLockData.lock.userEmail}`);
        return false;
      }

      // If not locked, try to acquire lock
      const lockRequest = {
        path: pathString,
        userId: user.sub,
        userEmail: user.email
      };
      console.log('Sending lock request:', lockRequest);

      const response = await fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/acquireLock`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(lockRequest)
      });
      
      const data = await response.json();
      console.log('Lock acquisition response:', data);

      if (data.success) {
        setIsLocked(true);
        setLockHolder(user.sub);
        setLockHolderEmail(user.email);
        return true;
      }
      
      if (data.lock) {
        setIsLocked(true);
        setLockHolder(data.lock.userId);
        setLockHolderEmail(data.lock.userEmail);
        setErrorMessage(`This file is currently locked by ${data.lock.userEmail}`);
        return false;
      }

      setErrorMessage('Could not acquire lock. Please try again.');
      return false;

    } catch (error) {
      console.error('Error in acquireLock:', error.message);
      setErrorMessage('Error acquiring lock. Please try again.');
      return false;
    }
  };

  const releaseLock = async (path) => {
    try {
      if (!path || !user || !user.sub) {
        console.error('Cannot release lock: path or user ID is missing');
        return;
      }
      
      const response = await fetch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/releaseLock`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          path: path,
          userId: user.sub 
        })
      });
      
      if (response.ok) {
        setIsLocked(false);
        setLockHolder(null);
        setLockHolderEmail(null);
      } else {
        const data = await response.json();
        console.error('Failed to release lock:', data.message || 'Unknown error');
      }
    } catch (error) {
      console.error('Error releasing lock:', error);
    }
  };

  const handleRollback = () => {
    setEditedHtmlContent(initialLoadContent);
    setOriginalHtmlContent(initialLoadContent);
    setIsRollbackModalVisible(false);
  };

  const previewHtml = () => {
    // Create a new HTML Blob with the current edited content
    const htmlBlob = new Blob([editedHtmlContent], { type: 'text/html' });
    
    // Create a URL for this blob
    const blobUrl = URL.createObjectURL(htmlBlob);
    
    // Open the URL in a new tab
    window.open(blobUrl, '_blank');
    
    // Clean up the URL object after a delay to ensure it opens properly
    setTimeout(() => {
      URL.revokeObjectURL(blobUrl);
    }, 1000);
  };

  // Function to handle HTML validation
  const validateHtml = async () => {
    if (!editedHtmlContent) {
      showNotification('error', 'Validation Error', 'No HTML content to validate');
      return;
    }

    setIsValidating(true);
    setIsValidatorVisible(true);
    setValidationResults([]);
    setValidationStatus(null);

    try {
      // Using the Nu HTML Checker API (validator.w3.org)
      const REACT_APP_BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
      
      // Send the HTML content to our backend for validation
      const response = await fetch(`${REACT_APP_BACKEND_ENDPOINT}/validateHtml`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ html: editedHtmlContent })
      });

      const data = await response.json();
      
      if (data.success) {
        // Process validation results
        setValidationResults(data.messages || []);
        
        // Determine overall validation status
        if (data.messages.length === 0) {
          setValidationStatus('success');
          showNotification('success', 'Validation Successful', 'No HTML errors found');
        } else {
          const hasErrors = data.messages.some(msg => msg.type === 'error');
          setValidationStatus(hasErrors ? 'error' : 'warning');
          
          if (hasErrors) {
            showNotification('error', 'Validation Failed', `Found ${data.messages.length} issues`);
          } else {
            showNotification('warning', 'Validation Warning', `Found ${data.messages.length} minor issues`);
          }
        }
      } else {
        setValidationStatus('error');
        setValidationResults([{
          type: 'error',
          message: data.message || 'Failed to validate HTML',
          line: 0,
          column: 0
        }]);
        showNotification('error', 'Validation Error', 'Failed to validate HTML');
      }
    } catch (error) {
      console.error('Error validating HTML:', error);
      setValidationStatus('error');
      setValidationResults([{
        type: 'error',
        message: `Validation service error: ${error.message}`,
        line: 0,
        column: 0
      }]);
      showNotification('error', 'Validation Error', 'Service unavailable');
    } finally {
      setIsValidating(false);
    }
  };

  // Function to close the validator modal
  const closeValidator = () => {
    setIsValidatorVisible(false);
  };

  // Function to open FTP browser modal
  const showFtpBrowser = () => {
    setIsFtpBrowserVisible(true);
  };

  // Function to handle file selection from FTP browser
  const handleFtpFileSelect = (path) => {
    setFilePath(path);
    setIsFtpBrowserVisible(false);
  };

  // Define columns for the validation results table
  const validationColumns = [
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      render: type => {
        let color = 'green';
        let icon = <CheckCircleOutlined />;
        
        if (type === 'error') {
          color = 'red';
          icon = <CloseCircleOutlined />;
        } else if (type === 'warning' || type === 'info') {
          color = 'orange';
          icon = <WarningOutlined />;
        }
        
        return (
          <Tag color={color} icon={icon}>
            {typeof type === 'string' ? type.toUpperCase() : 'UNKNOWN'}
          </Tag>
        );
      },
      width: 120,
    },
    {
      title: 'Location',
      key: 'location',
      render: (_, record) => (
        <span>Line {record.line}, Column {record.column}</span>
      ),
      width: 150,
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
    },
  ];

  const forciblyRemoveLock = async () => {
    const REACT_APP_BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
    
    console.log("Attempting to forcibly remove lock for:", filePath);
    
    if (!filePath || !user || !user.sub || !user.email) {
      alert('Cannot force unlock: file path or user information is missing');
      return;
    }
    
    try {
      // Clear UI state first to prevent stale state
      setErrorMessage(null);
      setIsLoading(true);
      
      // Send force unlock request
      console.log("Sending force lock release request");
      const response = await fetch(`${REACT_APP_BACKEND_ENDPOINT}/forceLockRelease`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          path: filePath,
          userId: user.sub,
          userEmail: user.email
        })
      });
      
      const data = await response.json();
      console.log("Force unlock response:", data);
      
      if (data.success) {
        console.log("Lock successfully removed");
        
        // After successful force unlock, the file should have no lock
        setIsLocked(false);
        setLockHolder(null);
        setLockHolderEmail(null);
        
        // Show success message
        showNotification('success', 'Lock Removed', 'File lock has been forcibly removed');
        
        // Acquire lock for current user right away to prevent race conditions
        console.log("Acquiring lock for current user after force unlock");
        const lockResponse = await fetch(`${REACT_APP_BACKEND_ENDPOINT}/acquireLock`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            path: filePath,
            userId: user.sub,
            userEmail: user.email
          })
        });
        
        const lockData = await lockResponse.json();
        console.log("Lock acquisition after force unlock:", lockData);
        
        if (lockData.success) {
          // Update state with new lock info
          setIsLocked(true);
          setLockHolder(user.sub);
          setLockHolderEmail(user.email);
          
          // Now download the file content
          console.log("Downloading file content after force unlock");
          const downloadResponse = await fetch(`${REACT_APP_BACKEND_ENDPOINT}/triggerDownload?path=${encodeURIComponent(filePath)}`);
          
          if (!downloadResponse.ok) {
            throw new Error(`HTTP error! Status: ${downloadResponse.status}`);
          }
          
          const fileData = await downloadResponse.json();
          
          if (fileData.success) {
            // Save the received content
            setInitialLoadContent(fileData.fileContent);
            setOriginalHtmlContent(fileData.fileContent);
            setEditedHtmlContent(fileData.fileContent);
            
            // Clear any error message
            setErrorMessage(null);
            
            // Initialize file load time for editing time tracking
            setFileLoadTime(Date.now());
            
            // Success notification
            showNotification('success', 'Success', 'File loaded and locked successfully');
          } else {
            setErrorMessage(`Error loading file: ${fileData.message}`);
          }
        } else {
          setErrorMessage(`Error acquiring lock after force unlock: ${lockData.message}`);
        }
        
        // Complete loading
        setIsLoading(false);
      } else {
        console.log("Failed to remove lock:", data.message);
        setIsLoading(false);
        alert(`Failed to release lock: ${data.message}`);
      }
    } catch (error) {
      console.error('Error during force unlock:', error);
      setIsLoading(false);
      alert(`Error during force unlock: ${error.message}`);
    }
  };

  return (
    <div className="developer-page">
      <FileControlsDeveloper
        filePath={filePath}
        setFilePath={handleFileChange}
        triggerFileDownload={handleLoadNewFile}
        saveContentEdits={saveContentEdits}
        saveDisabled={isLocked && lockHolder !== user.sub}
        onRollbackClick={() => setIsRollbackModalVisible(true)}
        showRollback={!!initialLoadContent && initialLoadContent !== editedHtmlContent}
        previewHtml={previewHtml}
        showPreview={!!editedHtmlContent}
        onBrowseFtp={showFtpBrowser}
        onValidateHtml={validateHtml}
        downloadLoading={isLoading}
      />

      <RollbackModal
        visible={isRollbackModalVisible}
        onClose={() => setIsRollbackModalVisible(false)}
        originalContent={initialLoadContent}
        currentContent={editedHtmlContent}
        onRollback={handleRollback}
      />

      <div style={{ marginBottom: '20px' }}>
        <div style={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: '10px',
          padding: '10px',
          backgroundColor: '#f0f2f5',
          borderRadius: '4px'
        }}>
          <span>
            Status: {isLocked ? 
              (lockHolder === user.sub ? 
                '🔒 You have locked this file for editing' : 
                `🔒 Locked by ${lockHolderEmail || 'another user'}`) : 
              '🔓 Unlocked'}
          </span>
          {isLocked && lockHolder === user.sub && (
            <span style={{ color: 'green', fontSize: '0.9em' }}>
              (This lock prevents others from editing while you work)
            </span>
          )}
        </div>

        {/* Display saved file message */}
        {savedFileMessage && (
          <div style={{
            marginTop: '8px',
            padding: '8px 12px',
            backgroundColor: '#f6ffed',
            border: '1px solid #b7eb8f',
            borderRadius: '4px',
            color: '#52c41a',
            display: 'flex',
            alignItems: 'center'
          }}>
            <span style={{ fontWeight: 500 }}>✓</span>
            <span style={{ marginLeft: '8px' }}>{savedFileMessage}</span>
          </div>
        )}

        {/* Don't show error message if it's just saying the file is locked by the current user */}
        {errorMessage && !(isLocked && lockHolder === user.sub && errorMessage.includes(user.email)) && (
          <div style={{
            marginTop: '8px',
            padding: '8px',
            backgroundColor: '#fff2f0',
            border: '1px solid #ffccc7',
            borderRadius: '4px',
            color: '#cf1322',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
            <span>⚠️ {errorMessage}</span>
            {(errorMessage.includes('locked by') || errorMessage.includes('File is locked') || 
              (isLocked && lockHolder !== user.sub)) && (
              <button 
                onClick={() => forciblyRemoveLock()}
                style={{
                  marginLeft: '10px',
                  padding: '5px 10px',
                  backgroundColor: '#ff4d4f',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer'
                }}
              >
                Force Unlock
              </button>
            )}
          </div>
        )}

        {/* Show loading indicator if loading */}
        {isLoading && !errorMessage && (
          <div style={{
            marginTop: '8px',
            padding: '8px 12px',
            backgroundColor: '#e6f7ff',
            border: '1px solid #91d5ff',
            borderRadius: '4px',
            color: '#1890ff',
            display: 'flex',
            alignItems: 'center'
          }}>
            <Spin size="small" style={{ marginRight: '8px' }} />
            <span>Loading file content, please wait...</span>
          </div>
        )}
      </div>

      <DeveloperEditor
        content={editedHtmlContent}
        onContentChange={handleContentChange}
      />

      {/* PdfControls component temporarily hidden
      <PdfControls />
      */}

      <CustomUpload 
        backendEndpoint={process.env.REACT_APP_BACKEND_ENDPOINT}
        showNotification={showNotification}
      />

      {/* HTML Validator Modal */}
      <Modal
        title="HTML Validation Results"
        open={isValidatorVisible}
        onCancel={closeValidator}
        width={800}
        footer={[
          <Button key="close" onClick={closeValidator}>
            Close
          </Button>
        ]}
      >
        {isValidating ? (
          <div style={{ textAlign: 'center', padding: '30px' }}>
            <Spin size="large" />
            <p style={{ marginTop: '10px' }}>Validating HTML...</p>
          </div>
        ) : (
          <div>
            {validationStatus === 'success' && (
              <Alert
                message="Validation Successful"
                description="Your HTML is valid and follows best practices."
                type="success"
                showIcon
                style={{ marginBottom: '16px' }}
              />
            )}
            
            {validationStatus === 'warning' && (
              <Alert
                message="Validation Warning"
                description="Your HTML has some warnings but no critical errors."
                type="warning"
                showIcon
                style={{ marginBottom: '16px' }}
              />
            )}
            
            {validationStatus === 'error' && (
              <Alert
                message="Validation Failed"
                description="Your HTML has errors that should be fixed."
                type="error"
                showIcon
                style={{ marginBottom: '16px' }}
              />
            )}
            
            <Table 
              columns={validationColumns}
              dataSource={validationResults.map((result, index) => ({
                ...result,
                key: index
              }))}
              pagination={{ pageSize: 10 }}
              rowClassName={(record) => record.type === 'error' ? 'validation-error-row' : ''}
            />
          </div>
        )}
      </Modal>

      {/* FTP Browser Modal */}
      <Modal
        title="Browse FTP Folders"
        open={isFtpBrowserVisible}
        onCancel={() => setIsFtpBrowserVisible(false)}
        width={800}
        footer={null}
        bodyStyle={{ padding: 0, height: '700px' }}
      >
        <FtpBrowser onSelectFile={handleFtpFileSelect} />
      </Modal>
    </div>
  );
};

export default DeveloperPage;
