import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';

// Configure frontend URL consistently
const getFrontendUrl = () => {
  // Check for environment variable first
  if (process.env.REACT_APP_FRONTEND_URL) {
    return process.env.REACT_APP_FRONTEND_URL;
  }
  
  // Otherwise, use window location with port adjustment if needed
  const urlParts = window.location.origin.split(':');
  // For localhost development, ensure we're using port 3000 (React default)
  if (urlParts.length > 2 && window.location.hostname === 'localhost') {
    return `${urlParts[0]}:${urlParts[1]}:3000`;
  }
  
  // For production deployments
  return window.location.origin;
};

const frontendUrl = getFrontendUrl();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH_DOMAIN}
    clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
    // redirectUri='https://ftp-ez.teigatechnology.com/'
    // redirectUri='http://localhost:3000/'
    redirectUri={frontendUrl}
    cacheLocation="localstorage"
  >
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </React.StrictMode>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();