import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
  CssBaseline,
  Button,
  Chip
} from '@mui/material';
import {
  Menu as MenuIcon,
  Assessment as LogsIcon,
  Code as DeveloperIcon,
  Backup as BackupIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Login as LoginIcon,
  Logout as LogoutIcon,
  People as UsersIcon,
  AdminPanelSettings as AdminIcon
} from '@mui/icons-material';

const drawerWidth = 240;

// Mixins for drawer transitions
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = ({ theme, handleDrawerClose }) => (
  <Box 
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    }}
  >
    <IconButton onClick={handleDrawerClose}>
      {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
    </IconButton>
  </Box>
);

const Layout = ({ children }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
  const [userRole, setUserRole] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    // Fetch user role if authenticated
    const fetchUserRole = async () => {
      if (isAuthenticated && user?.email) {
        try {
          const REACT_APP_BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT || 'http://localhost:3001';
          const response = await fetch(`${REACT_APP_BACKEND_ENDPOINT}/api/user-role?email=${encodeURIComponent(user.email)}`);
          
          if (response.ok) {
            const data = await response.json();
            setUserRole(data.role);
            setIsAdmin(data.isAdmin);
          }
        } catch (error) {
          console.error('Error fetching user role:', error);
        }
      }
    };
    
    fetchUserRole();
  }, [isAuthenticated, user]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogin = () => {
    loginWithRedirect();
  };

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  const menuItems = [
    { text: 'Logs', icon: <LogsIcon />, path: '/logs' },
    { text: 'Developer', icon: <DeveloperIcon />, path: '/developer' },
    { text: 'Backups', icon: <BackupIcon />, path: '/backups' }
  ];
  
  // Add User Management for admins
  if (isAdmin) {
    menuItems.push({ text: 'User Management', icon: <UsersIcon />, path: '/users' });
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: theme.palette.gunmetal.main, // Using Gunmetal color
          boxShadow: 3,
          zIndex: theme.zIndex.drawer + 1,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }),
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <Box 
              component="img"
              src={`${process.env.PUBLIC_URL}/primary_logo_tgt.png`}
              alt="Teiga Technology Logo"
              sx={{ 
                height: 40, 
                marginRight: 2,
                display: { xs: 'none', sm: 'block' } 
              }}
            />
            FTP-EZ Tool
          </Typography>
          {!isAuthenticated ? (
            <Button
              color="inherit"
              startIcon={<LoginIcon />}
              onClick={handleLogin}
            >
              Login
            </Button>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {user && (
                <Box sx={{ mr: 2, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                  <Typography variant="body2" sx={{ mb: 0.5 }}>
                    {user.email}
                  </Typography>
                  {userRole && (
                    <Chip
                      size="small"
                      label={typeof userRole === 'string' ? userRole.toUpperCase() : 'USER'}
                      color={userRole === 'admin' ? 'success' : 'primary'}
                      icon={userRole === 'admin' ? <AdminIcon sx={{ fontSize: '0.8rem !important' }} /> : null}
                      sx={{ 
                        height: 20, 
                        '& .MuiChip-label': { 
                          px: 1, 
                          fontSize: '0.65rem',
                          fontWeight: 'bold'
                        }
                      }}
                    />
                  )}
                </Box>
              )}
              <Button
                color="inherit"
                startIcon={<LogoutIcon />}
                onClick={handleLogout}
              >
                Logout
              </Button>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          whiteSpace: 'nowrap',
          boxSizing: 'border-box',
          ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
          }),
          ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
          }),
        }}
        open={open}
      >
        <DrawerHeader theme={theme} handleDrawerClose={handleDrawerClose} />
        <Divider sx={{ backgroundColor: theme.palette.secondary.dark }} />
        <List>
          {menuItems.map((item) => (
            <ListItem key={item.text} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  backgroundColor: location.pathname === item.path ? 
                    'rgba(115, 135, 134, 0.15)' : 'transparent',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  // Always use a full window location change to force a complete reload
                  window.location.href = item.path;
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: location.pathname === item.path ? '#f5f5f5' : theme.palette.secondary.main,
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText 
                  primary={item.text} 
                  sx={{ 
                    opacity: open ? 1 : 0,
                    '& .MuiTypography-root': {
                      color: location.pathname === item.path ? '#f5f5f5' : theme.palette.secondary.main,
                    }
                  }} 
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{ 
        flexGrow: 1, 
        p: 3, 
        mt: 8,
        width: '100%',
        overflow: 'auto',
        height: 'calc(100vh - 64px)' // Subtract the height of the AppBar
      }}>
        {children}
      </Box>
    </Box>
  );
};

export default Layout; 