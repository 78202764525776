import React, { useState } from 'react';
import MonacoEditor from './MonacoEditor';
import { Button, Card } from 'antd';

const TestEditor = () => {
  const [content, setContent] = useState('<html>\n  <body>\n    <h1>Test HTML</h1>\n    <p>This is a test</p>\n  </body>\n</html>');
  
  return (
    <Card title="Monaco Editor Test" style={{ margin: '20px' }}>
      <div style={{ marginBottom: '10px' }}>
        <Button 
          onClick={() => console.log('Current content:', content)}
          type="primary" 
          style={{ marginRight: '10px' }}
        >
          Log Content
        </Button>
        <Button 
          onClick={() => setContent('<html>\n  <body>\n    <h1>Reset Content</h1>\n  </body>\n</html>')}
        >
          Reset Content
        </Button>
      </div>
      
      <MonacoEditor 
        content={content} 
        onContentChange={(newContent) => {
          console.log('Content changed');
          setContent(newContent);
        }} 
      />
      
      <div style={{ marginTop: '20px' }}>
        <h4>Content Preview:</h4>
        <pre style={{ 
          backgroundColor: '#f5f5f5', 
          padding: '10px', 
          borderRadius: '4px',
          maxHeight: '200px',
          overflow: 'auto'
        }}>
          {content}
        </pre>
      </div>
    </Card>
  );
};

export default TestEditor; 