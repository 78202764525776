import React, { useState } from 'react';
import { Button, Input, Dropdown, Menu } from 'antd';
import { 
  UserOutlined, 
  ReloadOutlined, 
  EyeOutlined, 
  FolderOutlined, 
  ToolOutlined,
  RollbackOutlined,
  MenuOutlined,
  CheckCircleOutlined,
  CodeOutlined,
  UnlockOutlined,
  WarningOutlined
} from '@ant-design/icons';

const FileControlsDeveloper = ({ 
  filePath, 
  setFilePath, 
  triggerFileDownload, 
  saveContentEdits, 
  saveDisabled = false,
  onRollbackClick,
  showRollback = false,
  previewHtml,
  showPreview = false,
  onBrowseFtp,
  onValidateHtml,
  downloadLoading = false,
  showNotification
}) => {
  const [toolsMenuOpen, setToolsMenuOpen] = useState(false);

  // Add a clearAllLocks function that calls the admin endpoint
  const clearAllLocks = async () => {
    try {
      const REACT_APP_BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT || 'http://localhost:3001';
      const response = await fetch(`${REACT_APP_BACKEND_ENDPOINT}/admin/clearAllLocks`);
      const data = await response.json();
      
      if (data.success) {
        showNotification('success', 'Locks Cleared', 'All file locks have been cleared');
      } else {
        showNotification('error', 'Error', `Failed to clear locks: ${data.message}`);
      }
    } catch (error) {
      console.error('Error clearing locks:', error);
      showNotification('error', 'Error', `Failed to clear locks: ${error.message}`);
    }
  };

  // Create tools menu
  const toolsMenu = (
    <Menu>
      <Menu.Item key="ftp-browser" icon={<FolderOutlined />} onClick={onBrowseFtp}>
        Browse FTP Folders
      </Menu.Item>
      
      <Menu.Item 
        key="preview-html" 
        icon={<EyeOutlined />} 
        onClick={previewHtml}
        disabled={!showPreview}
      >
        Preview HTML
      </Menu.Item>
      
      <Menu.Item 
        key="rollback" 
        icon={<RollbackOutlined />} 
        onClick={onRollbackClick}
        disabled={!showRollback}
      >
        Compare & Roll Back
      </Menu.Item>

      <Menu.Item 
        key="validate-html" 
        icon={<CheckCircleOutlined />} 
        onClick={onValidateHtml}
        disabled={!showPreview}
      >
        Validate HTML
      </Menu.Item>

      <Menu.Divider />

      <Menu.Item 
        key="clear-locks" 
        icon={<UnlockOutlined />} 
        onClick={clearAllLocks}
        danger
      >
        Emergency: Clear All Locks
      </Menu.Item>
    </Menu>
  );

  const buttonStyle = {
    marginLeft: '10px',
    marginTop: '10px'
  };

  return (
    <div id="controls" style={{ marginBottom: '20px' }}>
      <div style={{ display: 'flex', marginBottom: '10px' }}>
        <Input
          size='large'
          type="text"
          value={filePath}
          onChange={(e) => setFilePath(e.target.value)}
          placeholder="Enter URL of the HTML file"
          id='input_filepath'
          prefix={<UserOutlined />}
          style={{ flex: 1 }}
        />
        <Button 
          type="primary"
          onClick={onBrowseFtp}
          style={{
            marginLeft: '10px',
            background: '#00b8d4',
            borderColor: '#0088a3',
          }}
          icon={<FolderOutlined />}
        >
          Browse
        </Button>
      </div>

      {/* Lock status indicator */}
      {saveDisabled && (
        <div style={{
          padding: '8px 12px',
          backgroundColor: '#fff2f0',
          border: '1px solid #ffccc7',
          borderRadius: '4px',
          color: '#cf1322',
          marginBottom: '10px'
        }}>
          <WarningOutlined /> This file is currently being edited by another user. Use Force Unlock to take control.
        </div>
      )}

      <div id='btn_filecontrol'>
        <Button 
          id='btn_primary' 
          type="primary" 
          onClick={triggerFileDownload}
          style={buttonStyle}
          loading={downloadLoading}
        >
          Display File To Edit
        </Button>
           
        <Button 
          type="default" 
          onClick={saveContentEdits}
          disabled={saveDisabled}
          style={{
            ...buttonStyle,
            opacity: saveDisabled ? 0.5 : 1,
            cursor: saveDisabled ? 'not-allowed' : 'pointer'
          }}
        >
          Save Edits
        </Button>
        
        {/* Tools dropdown button */}
        <Dropdown overlay={toolsMenu} trigger={['click']} placement="bottomRight">
          <Button
            type="primary"
            style={{
              ...buttonStyle,
              background: '#00b8d4',
              borderColor: '#0088a3',
            }}
            icon={<ToolOutlined />}
          >
            Tools
          </Button>
        </Dropdown>
      </div>
    </div>
  );
};

export default FileControlsDeveloper;
