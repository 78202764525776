import React, { useState, useEffect, useRef } from 'react';
import { Upload, message, Modal, Table, Input } from 'antd';
import { SearchOutlined, CopyOutlined, CheckOutlined } from '@ant-design/icons';
import { 
  Box, 
  Button, 
  TextField, 
  Typography, 
  Alert, 
  Paper, 
  CircularProgress,
  Divider,
  IconButton,
  Breadcrumbs,
  Chip,
  Tooltip,
  Snackbar
} from '@mui/material';
import { 
  CloudUpload as UploadIcon, 
  InsertDriveFile as FileIcon,
  Delete as DeleteIcon,
  Lock as LockIcon,
  Security as SecurityIcon,
  Code as CodeIcon,
  Folder as FolderIcon,
  ArrowUpward as ArrowUpIcon,
  Refresh as RefreshIcon,
  Home as HomeIcon,
  ChevronRight as NavigateIcon,
  Search as SearchIcon,
  ContentCopy as CopyIcon,
  Check as SuccessIcon,
  OpenInNew as OpenInNewIcon
} from '@mui/icons-material';
import { useAuth0 } from '@auth0/auth0-react';

const CustomUpload = ({ backendEndpoint, showNotification }) => {
  const [customFile, setCustomFile] = useState(null);
  const [customUploadUrl, setCustomUploadUrl] = useState('');
  const [notification, setNotification] = useState(null);
  const [uploadState, setUploadState] = useState('idle'); // idle, uploading, success, error
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isDirectoryBrowserOpen, setIsDirectoryBrowserOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState('/');
  const [fileList, setFileList] = useState([]);
  const [filteredFileList, setFilteredFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pathHistory, setPathHistory] = useState([{ name: 'Root', path: '/' }]);
  const [selectedPath, setSelectedPath] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [uploadedFileUrl, setUploadedFileUrl] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const [fullUploadPath, setFullUploadPath] = useState('');
  
  // Add a ref to track if component is mounted
  const isMountedRef = useRef(true);
  
  // Get user information from Auth0
  const { user } = useAuth0();
  
  // Update mounted ref on unmount
  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  // Update the full path whenever file or upload location changes
  useEffect(() => {
    if (customFile && customUploadUrl) {
      // Format the path correctly
      let formattedPath = customUploadUrl;
      if (!formattedPath.startsWith('/')) formattedPath = '/' + formattedPath;
      if (formattedPath.endsWith('/')) formattedPath = formattedPath.slice(0, -1);
      
      // Create the full path with filename
      const fullPath = `${formattedPath}/${customFile.name}`;
      setFullUploadPath(fullPath);
    } else {
      setFullUploadPath('');
    }
  }, [customFile, customUploadUrl]);

  // Use a default value for backendEndpoint if not provided
  const endpoint = backendEndpoint || 'http://localhost:5000';

  const handleCustomFileChange = (info) => {
    // Check if there's a file in the current upload event
    if (info.fileList.length > 0) {
      const file = info.fileList[0].originFileObj;
      setCustomFile(file);
    } else {
      // Clear the file state if no file is selected
      setCustomFile(null);
    }
  };

  const handleCustomUploadUrlChange = (e) => {
    setCustomUploadUrl(e.target.value);
  };

  const clearFile = () => {
    setCustomFile(null);
  };

  // Function to copy text to clipboard
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setIsCopied(true);
        // Reset copy icon after 2 seconds
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
        
        if (showNotification) {
          showNotification('success', 'Copied!', 'URL copied to clipboard');
        }
      },
      (err) => {
        console.error('Could not copy text: ', err);
        if (showNotification) {
          showNotification('error', 'Error', 'Failed to copy URL');
        }
      }
    );
  };

  // Handle search functionality
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    
    if (!query) {
      setFilteredFileList(fileList);
    } else {
      const filtered = fileList.filter(item => 
        item.name.toLowerCase().includes(query)
      );
      setFilteredFileList(filtered);
    }
  };

  // Directory browser functions
  const fetchDirectory = async (path) => {
    setLoading(true);
    try {
      const response = await fetch(`${endpoint}/listFtpDirectory?path=${encodeURIComponent(path)}`);
      const data = await response.json();
      
      if (data.success) {
        setFileList(data.items);
        setFilteredFileList(data.items);
        setCurrentPath(path);
        // Clear any search when navigating to a new directory
        setSearchQuery('');
      }
    } catch (error) {
      console.error('Error fetching directory:', error);
    }
    setLoading(false);
  };

  // Shortcut paths for common directories
  const commonPaths = {
    TCC: '/TheCorporateCounsel.net/member/Memos/',
    DL: '/DealLawyers.com/Member/MemoFiles/',
    SEC16: '/Section16.net/member/Memos/',
    CompStan: '/CompensationStandards.com/Member/Memos/'
  };

  // Navigate to a common path
  const navigateToCommonPath = (pathKey) => {
    const path = commonPaths[pathKey];
    if (path) {
      fetchDirectory(path);
      // Create a path history for breadcrumbs
      const pathParts = path.split('/').filter(p => p);
      const newHistory = [{ name: 'Root', path: '/' }];
      
      let currentPathSoFar = '';
      pathParts.forEach((part, index) => {
        currentPathSoFar += '/' + part;
        newHistory.push({
          name: part,
          path: currentPathSoFar
        });
      });
      
      setPathHistory(newHistory);
    }
  };

  const handleDirectoryBrowse = () => {
    setIsDirectoryBrowserOpen(true);
    fetchDirectory('/');
    setPathHistory([{ name: 'Root', path: '/' }]);
  };

  const handleNavigate = (path, name) => {
    fetchDirectory(path);
    if (path === '/') {
      setPathHistory([{ name: 'Root', path: '/' }]);
    } else {
      // Find if this path exists in history
      const existingIndex = pathHistory.findIndex(item => item.path === path);
      if (existingIndex >= 0) {
        // If exists, truncate history to this point
        setPathHistory(pathHistory.slice(0, existingIndex + 1));
      } else {
        // Otherwise add to history
        setPathHistory([...pathHistory, { name, path }]);
      }
    }
  };

  const handleGoUp = () => {
    if (currentPath === '/') return;
    const newPath = currentPath.split('/').slice(0, -1).join('/') || '/';
    setPathHistory(pathHistory.slice(0, -1));
    fetchDirectory(newPath);
  };

  const handleGoHome = () => {
    fetchDirectory('/');
    setPathHistory([{ name: 'Root', path: '/' }]);
  };

  const handleRefresh = () => {
    fetchDirectory(currentPath);
  };

  const handleRowDoubleClick = (record) => {
    if (record.type === 'd') {
      handleNavigate(record.path, record.name);
    }
  };

  const handleSelectDirectory = () => {
    setSelectedPath(currentPath);
    setCustomUploadUrl(currentPath);
    setIsDirectoryBrowserOpen(false);
  };

  const handleCustomUpload = () => {
    if (!customFile || !customUploadUrl) {
      setNotification({
        type: 'error',
        message: 'Please select a file and enter a valid upload URL.'
      });
      return;
    }

    // Set state to uploading and reset progress
    setUploadState('uploading');
    setUploadProgress(0);
    setNotification(null);
    setUploadedFileUrl('');
    
    // Get the filename for later use
    const filename = customFile.name;
    
    const xhr = new XMLHttpRequest();
    
    // Set up progress monitoring
    xhr.upload.addEventListener('progress', function(e) {
      if (e.lengthComputable) {
        const percentComplete = Math.round((e.loaded / e.total) * 100);
        setUploadProgress(percentComplete);
        
        // When upload reaches 100%, show the success message
        if (percentComplete === 100) {
          console.log('Upload reached 100%, showing success alert');
          // Use timeout to give a small delay for visual feedback
          setTimeout(() => {
            showSuccessAlert(filename);
          }, 500);
        }
      }
    });
    
    // Set up a safety timer to avoid UI being stuck in uploading state
    const safetyTimer = setTimeout(() => {
      if (uploadState === 'uploading') {
        console.error('Upload timeout - fallback to error state');
        setUploadState('error');
        setNotification({
          type: 'error',
          message: 'Upload timed out'
        });
      }
    }, 120000); // 2 minute timeout

    // Helper to force a success state when needed
    const forceSuccessState = (filename, uploadPath) => {
      console.log('Forcing success state with filename:', filename);
      let uploadUrl = '';
      if (!uploadPath.startsWith('/')) uploadPath = '/' + uploadPath;
      if (uploadPath.endsWith('/')) uploadPath = uploadPath.slice(0, -1);
      uploadUrl = `${uploadPath}/${filename}`;
      
      setUploadedFileUrl(uploadUrl);
      setSelectedPath(uploadPath);
      setUploadState('success');
      setNotification({
        type: 'success',
        message: `File "${filename}" uploaded successfully!`
      });
      setCustomFile(null);
    };

    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        console.log('XHR completed with status:', xhr.status);
        clearTimeout(safetyTimer);
        
        // Log response details for debugging
        console.log('Response text:', xhr.responseText);
        
        if (xhr.status >= 200 && xhr.status < 300) {
          try {
            const data = JSON.parse(xhr.responseText);
            console.log('Upload success response:', data);
            
            // Format the URL from server response
            let uploadUrl = '';
            if (data && data.url) {
              uploadUrl = data.url;
            } else {
              let uploadPath = customUploadUrl;
              if (!uploadPath.startsWith('/')) uploadPath = '/' + uploadPath;
              if (uploadPath.endsWith('/')) uploadPath = uploadPath.slice(0, -1);
              uploadUrl = `${uploadPath}/${filename}`;
            }
            
            // Update state with the upload result
            if (isMountedRef.current) {
              setUploadedFileUrl(uploadUrl);
              setSelectedPath(customUploadUrl);
              setUploadState('success');
              setNotification({
                type: 'success',
                message: `File "${filename}" uploaded successfully!`
              });
              setCustomFile(null);
              
              // Show notification if callback is available
              if (showNotification) {
                showNotification('success', 'Upload Complete', `File "${filename}" uploaded successfully!`);
              }
            }
            
          } catch (error) {
            console.error('Error parsing response:', error, 'Raw response:', xhr.responseText);
            
            if (isMountedRef.current) {
              // If we can't parse the response but status is good, still consider it a success
              if (xhr.status === 200) {
                let uploadPath = customUploadUrl;
                if (!uploadPath.startsWith('/')) uploadPath = '/' + uploadPath;
                if (uploadPath.endsWith('/')) uploadPath = uploadPath.slice(0, -1);
                const uploadUrl = `${uploadPath}/${filename}`;
                
                setUploadedFileUrl(uploadUrl);
                setSelectedPath(customUploadUrl);
                setUploadState('success');
                setNotification({
                  type: 'success',
                  message: `File "${filename}" uploaded successfully!`
                });
                setCustomFile(null);
                
                if (showNotification) {
                  showNotification('success', 'Upload Complete', `File "${filename}" uploaded successfully!`);
                }
              } else {
                setUploadState('error');
                setNotification({
                  type: 'error',
                  message: 'Error processing server response'
                });
                
                if (showNotification) {
                  showNotification('error', 'Upload Error', 'Error processing server response');
                }
              }
            }
          }
        } else {
          console.error('Upload failed with status:', xhr.status);
          
          if (isMountedRef.current) {
            setUploadState('error');
            setNotification({
              type: 'error',
              message: `Upload failed with status: ${xhr.status}`
            });
            
            if (showNotification) {
              showNotification('error', 'Upload Failed', `Status code: ${xhr.status}`);
            }
          }
        }
      }
    };

    xhr.onerror = function() {
      console.error('XHR error occurred');
      clearTimeout(safetyTimer);
      setUploadState('error');
      setNotification({
        type: 'error',
        message: 'Network error during upload'
      });
    };

    xhr.open('POST', `${endpoint}/customUpload`);
    
    // Add user information to headers if available
    if (user) {
      xhr.setRequestHeader('X-User-ID', user.sub || '');
      xhr.setRequestHeader('X-User-Email', user.email || '');
      xhr.setRequestHeader('X-User-Name', user.name || '');
      xhr.setRequestHeader('X-Tool-Used', 'CustomUploadTool');
    }
    
    const formData = new FormData();
    formData.append('file', customFile);
    formData.append('destination', customUploadUrl);
    
    // Add user information to the form data as well (as backup)
    if (user) {
      formData.append('userId', user.sub || '');
      formData.append('userEmail', user.email || '');
      formData.append('userName', user.name || '');
      formData.append('toolUsed', 'CustomUploadTool');
    }
    
    // Start uploading
    setUploadState('uploading');
    xhr.send(formData);
  };

  // Create this success alert function
  const showSuccessAlert = (fileName) => {
    // Create an alert-style element that's very visible
    alert(`Upload Complete! File "${fileName}" has been uploaded successfully.`);
    
    // Update state to reflect success
    setUploadState('success');
    
    // Format success URL
    let uploadUrl = customUploadUrl;
    if (!uploadUrl.startsWith('/')) uploadUrl = '/' + uploadUrl;
    if (uploadUrl.endsWith('/')) uploadUrl = uploadUrl.slice(0, -1);
    uploadUrl = `${uploadUrl}/${fileName}`;
    
    setUploadedFileUrl(uploadUrl);
    
    // Show notification if available
    if (showNotification) {
      showNotification('success', 'Upload Complete', `File "${fileName}" uploaded successfully!`);
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      render: (text, record) => (
        <div className="file-item" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: 'left' }}>
          {record.type === 'd' ? (
            <FolderIcon style={{ marginRight: '8px', color: '#FFC107' }} />
          ) : (
            <FileIcon style={{ marginRight: '8px', color: '#90CAF9' }} />
          )}
          <span style={{ textAlign: 'left' }}>{text}</span>
        </div>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      align: 'left',
      render: (type) => <span style={{ textAlign: 'left' }}>{type === 'd' ? 'Directory' : 'File'}</span>,
    }
  ];

  const breadcrumbItems = pathHistory.map((path, index) => (
    <Typography
      key={path.path}
      color="inherit"
      onClick={() => handleNavigate(path.path, path.name)}
      style={{ 
        cursor: 'pointer', 
        display: 'flex', 
        alignItems: 'center',
        fontWeight: index === pathHistory.length - 1 ? 'bold' : 'normal',
        color: index === pathHistory.length - 1 ? '#00b8d4' : 'inherit'
      }}
    >
      {path.name}
      {index < pathHistory.length - 1 && (
        <NavigateIcon fontSize="small" style={{ marginLeft: 4, marginRight: 4 }} />
      )}
    </Typography>
  ));

  // Add a simple success message component
  const UploadSuccessMessage = () => (
    <div style={{
      marginTop: '16px',
      padding: '16px',
      backgroundColor: '#e8f5e9',
      border: '1px solid #81c784',
      borderRadius: '4px',
      textAlign: 'center'
    }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '8px' }}>
        <SuccessIcon style={{ color: '#2e7d32', marginRight: '8px' }} />
        <span style={{ color: '#2e7d32', fontWeight: 'bold' }}>Upload Completed Successfully!</span>
      </div>
      {uploadedFileUrl && (
        <div style={{ marginTop: '8px', wordBreak: 'break-all' }}>
          <strong>File URL:</strong> {uploadedFileUrl}
        </div>
      )}
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: '12px', backgroundColor: '#00b8d4' }}
        onClick={() => {
          setUploadState('idle');
          setUploadProgress(0);
          setCustomFile(null);
        }}
      >
        Upload Another File
      </Button>
    </div>
  );

  return (
    <Paper 
      elevation={0} 
      className="secure-upload-container"
      sx={{ position: 'relative', overflow: 'hidden' }}
    >
      <div className="circuit-pattern" />
      
      <Box sx={{ position: 'relative', zIndex: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <LockIcon sx={{ color: '#00b8d4', mr: 1 }} />
          <Typography variant="h6" sx={{ fontWeight: 500, color: '#000000' }}>Secure Upload</Typography>
        </Box>
        
        {notification && notification.type === 'error' && (
          <Alert
            severity={notification.type}
            onClose={() => setNotification(null)}
            sx={{ mb: 2 }}
          >
            {notification.message}
          </Alert>
        )}
        
        <div className="format-display">
          <CodeIcon className="format-icon" />
          <Typography variant="body2" className="format-text" sx={{ color: '#000000' }}>
            Format: <strong style={{ color: '#000000' }}>website.com/folder/Upload-Here/</strong>
          </Typography>
        </div>
        
        <div className="url-input-container">
          <TextField
            fullWidth
            className="url-input-field"
            placeholder="Enter the full URL of the location to upload"
            value={customUploadUrl}
            onChange={handleCustomUploadUrlChange}
            variant="outlined"
            size="small"
          />
          <Button 
            onClick={handleDirectoryBrowse}
            variant="outlined"
            className="browse-button"
          >
            <FolderIcon sx={{ mr: 1 }} />
            Browse
          </Button>
        </div>
        
        {selectedPath && (
          <div className="upload-location">
            <div className="upload-location-title">
              <FolderIcon sx={{ mr: 0.5, color: '#00b8d4' }} />
              Upload location:
            </div>
            <div className="upload-location-path">
              {fullUploadPath || selectedPath}
            </div>
          </div>
        )}
        
        <div className="file-selection">
          <Upload
            beforeUpload={() => false}
            onChange={handleCustomFileChange}
            showUploadList={false}
            maxCount={1}
            fileList={customFile ? [{ uid: '-1', name: customFile.name }] : []}
          >
            <Button 
              variant="outlined" 
              component="span"
              startIcon={<UploadIcon />}
              size="medium"
            >
              Select File
            </Button>
          </Upload>
          
          {customFile && (
            <div className="selected-file">
              <FileIcon fontSize="small" sx={{ color: '#00b8d4' }} />
              <Typography variant="body2" className="selected-file-name">
                {customFile.name}
              </Typography>
              <IconButton size="small" onClick={clearFile}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </div>
          )}
        </div>
        
        {uploadState === 'uploading' && (
          <div className="upload-progress">
            <div className="upload-progress-header">
              <CircularProgress size={24} sx={{ mr: 1, color: '#00b8d4' }} />
              <Typography variant="body2" sx={{ fontWeight: 500, color: '#00b8d4' }}>
                Uploading... {uploadProgress}%
              </Typography>
            </div>
            <div className="upload-progress-bar">
              <div 
                className="upload-progress-bar-fill"
                style={{ width: `${uploadProgress}%` }}
              />
            </div>
          </div>
        )}
        
        {uploadState === 'success' && (
          <div className="upload-success">
            <div className="upload-success-header">
              <SuccessIcon sx={{ mr: 1 }} />
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                Upload Completed Successfully!
              </Typography>
            </div>
            {uploadedFileUrl && (
              <div className="upload-success-url">
                <strong>File URL:</strong> {uploadedFileUrl}
                <IconButton 
                  size="small" 
                  onClick={() => copyToClipboard(uploadedFileUrl)}
                  color={isCopied ? "success" : "primary"}
                  sx={{ ml: 1 }}
                >
                  {isCopied ? <CheckOutlined /> : <CopyOutlined />}
                </IconButton>
              </div>
            )}
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              onClick={() => {
                setUploadState('idle');
                setUploadProgress(0);
                setCustomFile(null);
              }}
            >
              Upload Another File
            </Button>
          </div>
        )}
        
        <Button 
          variant="contained"
          fullWidth
          className="upload-button"
          startIcon={<UploadIcon />}
          onClick={handleCustomUpload}
          disabled={!customFile || !customUploadUrl || uploadState === 'uploading' || uploadState === 'success'}
        >
          {uploadState === 'uploading' ? 'Uploading...' : 'Upload File'}
        </Button>
      </Box>

      {/* Directory browser modal */}
      <Modal
        title="Select Upload Directory"
        open={isDirectoryBrowserOpen}
        onCancel={() => setIsDirectoryBrowserOpen(false)}
        width={800}
        className="directory-browser-modal"
        style={{ 
          top: 20,
          zIndex: 9999,
          position: 'absolute'
        }}
        wrapClassName="directory-browser-modal"
        maskStyle={{ zIndex: 9998 }}
        bodyStyle={{ 
          maxHeight: '70vh', 
          overflow: 'auto',
          position: 'relative',
          zIndex: 10000
        }}
        destroyOnClose={true}
        zIndex={9999}
        getContainer={() => document.body}
        keyboard={true}
        footer={[
          <Button key="cancel" onClick={() => setIsDirectoryBrowserOpen(false)}>
            Cancel
          </Button>,
          <Button 
            key="select" 
            type="primary" 
            onClick={handleSelectDirectory}
            style={{ 
              backgroundColor: '#00b8d4', 
              borderColor: '#0088a3',
              color: 'white'
            }}
          >
            Use This Directory
          </Button>
        ]}
      >
        <div style={{ overflow: 'visible' }}>
          <Box mb={2} display="flex" alignItems="center">
            <Tooltip title="Go to root directory">
              <IconButton onClick={handleGoHome} size="small">
                <HomeIcon />
              </IconButton>
            </Tooltip>
            
            <Tooltip title="Go up one level">
              <IconButton 
                onClick={handleGoUp} 
                disabled={currentPath === '/'}
                size="small"
              >
                <ArrowUpIcon />
              </IconButton>
            </Tooltip>
            
            <Tooltip title="Refresh current directory">
              <IconButton onClick={handleRefresh} size="small">
                <RefreshIcon />
              </IconButton>
            </Tooltip>
            
            <Divider orientation="vertical" flexItem style={{ margin: '0 8px' }} />
            
            <Paper elevation={0} style={{ padding: '4px 8px', flexGrow: 1 }}>
              <Breadcrumbs separator="" aria-label="breadcrumb">
                {breadcrumbItems}
              </Breadcrumbs>
            </Paper>
          </Box>
          
          <Box mb={2} display="flex" alignItems="center">
            <Typography variant="subtitle2" mr={1}>Current path:</Typography>
            <Chip 
              label={currentPath} 
              variant="outlined" 
              style={{ 
                fontFamily: 'monospace', 
                backgroundColor: '#e0f7fa', 
                borderColor: '#00b8d4',
                textAlign: 'left'
              }} 
            />
          </Box>
          
          {/* Search Box */}
          <Box mb={2}>
            <Input
              placeholder="Search directories..."
              prefix={<SearchOutlined style={{ color: '#00b8d4' }} />}
              value={searchQuery}
              onChange={handleSearch}
              allowClear
              className="directory-search-input"
              style={{ 
                width: '100%',
                borderColor: 'rgba(0, 184, 212, 0.3)',
                borderRadius: '4px',
                overflow: 'hidden',
                position: 'static'
              }}
            />
          </Box>
          
          {/* Shortcut Buttons for Common Folders */}
          <Box 
            mb={2} 
            sx={{ 
              display: 'flex', 
              gap: 1,
              flexWrap: 'wrap',
              justifyContent: 'flex-start'
            }}
          >
            <Typography variant="body2" sx={{ 
              mr: 1, 
              display: 'flex', 
              alignItems: 'center',
              color: 'rgba(0, 0, 0, 0.6)'
            }}>
              Quick access:
            </Typography>
            
            <Button 
              size="small" 
              variant="outlined"
              onClick={() => navigateToCommonPath('TCC')}
              sx={{ 
                borderColor: 'rgba(0, 184, 212, 0.5)',
                color: '#00b8d4',
                '&:hover': {
                  borderColor: '#00b8d4',
                  backgroundColor: 'rgba(0, 184, 212, 0.04)'
                },
                minWidth: 'auto',
                borderRadius: '4px'
              }}
            >
              TCC
            </Button>
            
            <Button 
              size="small" 
              variant="outlined"
              onClick={() => navigateToCommonPath('DL')}
              sx={{ 
                borderColor: 'rgba(0, 184, 212, 0.5)',
                color: '#00b8d4',
                '&:hover': {
                  borderColor: '#00b8d4',
                  backgroundColor: 'rgba(0, 184, 212, 0.04)'
                },
                minWidth: 'auto',
                borderRadius: '4px'
              }}
            >
              DL
            </Button>
            
            <Button 
              size="small" 
              variant="outlined"
              onClick={() => navigateToCommonPath('SEC16')}
              sx={{ 
                borderColor: 'rgba(0, 184, 212, 0.5)',
                color: '#00b8d4',
                '&:hover': {
                  borderColor: '#00b8d4',
                  backgroundColor: 'rgba(0, 184, 212, 0.04)'
                },
                minWidth: 'auto',
                borderRadius: '4px'
              }}
            >
              SEC16
            </Button>
            
            <Button 
              size="small" 
              variant="outlined"
              onClick={() => navigateToCommonPath('CompStan')}
              sx={{ 
                borderColor: 'rgba(0, 184, 212, 0.5)',
                color: '#00b8d4',
                '&:hover': {
                  borderColor: '#00b8d4',
                  backgroundColor: 'rgba(0, 184, 212, 0.04)'
                },
                minWidth: 'auto',
                borderRadius: '4px'
              }}
            >
              CompStan
            </Button>
          </Box>
          
          <Table 
            dataSource={filteredFileList} 
            columns={columns}
            rowKey="path"
            loading={loading}
            pagination={false}
            size="small"
            scroll={{ y: 280 }}
            style={{ maxHeight: '280px' }}
            onRow={(record) => ({
              onDoubleClick: () => handleRowDoubleClick(record),
              style: { cursor: 'pointer', textAlign: 'left' }
            })}
            locale={{ 
              emptyText: searchQuery 
                ? 'No matches found. Try a different search.' 
                : 'This folder is empty.' 
            }}
          />
        </div>
      </Modal>
    </Paper>
  );
};

export default CustomUpload;
