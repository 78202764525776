import React, { useState, useEffect } from 'react';
import { Table, Button, Input } from 'antd';
import { Resizable } from 're-resizable';
import { SearchOutlined } from '@ant-design/icons';
// Material UI imports
import { 
  Breadcrumbs, 
  Typography, 
  Paper, 
  Tooltip,
  IconButton,
  Box,
  Chip,
  Divider
} from '@mui/material';
import { 
  Folder as FolderIcon, 
  InsertDriveFile as FileIcon, 
  ArrowUpward as ArrowUpIcon, 
  Refresh as RefreshIcon,
  ChevronRight as NavigateIcon,
  UnfoldLess as HideIcon,
  UnfoldMore as ShowIcon,
  Home as HomeIcon,
  Security as SecurityIcon,
  LockOutlined as LockIcon,
  Search as SearchIcon
} from '@mui/icons-material';
import './FtpBrowser.css'; // Import the CSS file

const FtpBrowser = ({ onSelectFile }) => {
  const [currentPath, setCurrentPath] = useState('/');
  const [fileList, setFileList] = useState([]);
  const [filteredFileList, setFilteredFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pathHistory, setPathHistory] = useState([{ name: 'Root', path: '/' }]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [height, setHeight] = useState(500);
  const [searchQuery, setSearchQuery] = useState('');

  const fetchDirectory = async (path) => {
    setLoading(true);
    try {
      // Use environment variable or fallback to localhost:5000
      const backendEndpoint = process.env.REACT_APP_BACKEND_ENDPOINT || 'http://localhost:5000';
      const response = await fetch(`${backendEndpoint}/listFtpDirectory?path=${encodeURIComponent(path)}`);
      const data = await response.json();
      
      if (data.success) {
        setFileList(data.items);
        setFilteredFileList(data.items);
        setCurrentPath(path);
        // Clear any search when navigating to a new directory
        setSearchQuery('');
      }
    } catch (error) {
      console.error('Error fetching directory:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDirectory(currentPath);
  }, []);

  // Handle search functionality
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    
    if (!query) {
      setFilteredFileList(fileList);
    } else {
      const filtered = fileList.filter(item => 
        item.name.toLowerCase().includes(query)
      );
      setFilteredFileList(filtered);
    }
  };

  const handleNavigate = (path, name) => {
    fetchDirectory(path);
    if (path === '/') {
      setPathHistory([{ name: 'Root', path: '/' }]);
    } else {
      setPathHistory([...pathHistory, { name, path }]);
    }
  };

  const handleGoUp = () => {
    if (currentPath === '/') return;
    const newPath = currentPath.split('/').slice(0, -1).join('/') || '/';
    setPathHistory(pathHistory.slice(0, -1));
    fetchDirectory(newPath);
  };

  const handleRowDoubleClick = (record) => {
    if (record.type === 'd') {
      handleNavigate(record.path, record.name);
    } else {
      onSelectFile(record.path);
    }
  };

  // Get file extension to determine icon color
  const getFileIconProps = (fileName) => {
    const extension = fileName.split('.').pop()?.toLowerCase();
    
    // Define colors for different file types - updated to match the theme
    const extensionColors = {
      pdf: '#00b8d4', // primary teal
      html: '#18ffff', // light teal
      htm: '#18ffff', // light teal
      css: '#0088a3', // dark teal
      js: '#0088a3', // dark teal
      txt: '#424242', // secondary gray
      xml: '#757575', // lighter gray
      json: '#616161', // medium gray
      default: '#616161' // medium gray
    };
    
    return {
      color: extensionColors[extension] || extensionColors.default
    };
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      render: (text, record) => (
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1, 
          justifyContent: 'flex-start',
          width: '100%',
          textAlign: 'left'
        }}>
          {record.type === 'd' ? (
            <FolderIcon sx={{ color: '#00b8d4', flexShrink: 0 }} fontSize="small" />
          ) : (
            <FileIcon sx={{ ...getFileIconProps(text), flexShrink: 0 }} fontSize="small" />
          )}
          <Typography variant="body2" sx={{ textAlign: 'left' }}>{text}</Typography>
          {record.type === 'd' && (
            <Chip 
              size="small" 
              label="Directory" 
              sx={{ 
                height: 20, 
                fontSize: '0.7rem', 
                backgroundColor: 'rgba(0, 184, 212, 0.1)', 
                color: '#00b8d4',
                ml: 1,
                flexShrink: 0
              }} 
            />
          )}
        </Box>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: 100,
      align: 'left',
      render: (_, record) => (
        <Box>
          {record.type === 'd' ? (
            <Tooltip title="Open folder">
              <IconButton 
                size="small" 
                onClick={() => handleNavigate(record.path, record.name)}
                color="primary"
              >
                <NavigateIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          ) : (
            <Button 
              type="primary" 
              size="small" 
              onClick={() => onSelectFile(record.path)}
              style={{ 
                fontSize: '12px', 
                padding: '0 8px', 
                height: '24px', 
                background: 'linear-gradient(135deg, #00b8d4 0%, #0088a3 100%)',
                border: 'none',
                color: 'white'
              }}
            >
              Select
            </Button>
          )}
        </Box>
      ),
    },
  ];

  if (isCollapsed) {
    return (
      <Box 
        sx={{ 
          mt: 2, 
          pt: 1,
          pb: 1, 
          borderTop: '1px solid rgba(0, 184, 212, 0.2)', 
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Button 
          icon={<ShowIcon />} 
          onClick={() => setIsCollapsed(false)}
          type="text"
        >
          Show FTP Browser
        </Button>
      </Box>
    );
  }

  return (
    <Resizable
      style={{
        marginTop: '20px',
        borderTop: '1px solid rgba(0, 184, 212, 0.2)',
        padding: '10px',
        background: '#fff',
        position: 'relative',
        borderRadius: '8px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.05)'
      }}
      defaultSize={{
        width: '100%',
        height: height
      }}
      onResizeStop={(e, direction, ref, d) => {
        setHeight(height + d.height);
      }}
      minHeight={300}
      maxHeight={800}
      enable={{
        top: false,
        right: false,
        bottom: true,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false
      }}
    >
      {/* Security shield icon decoration */}
      <SecurityIcon 
        sx={{ 
          position: 'absolute', 
          top: 15, 
          right: 15, 
          color: 'rgba(0, 184, 212, 0.1)', 
          fontSize: 40,
          transform: 'rotate(10deg)',
          zIndex: 0
        }} 
      />
      
      <Paper 
        elevation={0} 
        sx={{ 
          height: '100%', 
          display: 'flex', 
          flexDirection: 'column', 
          p: 1, 
          position: 'relative',
          border: '1px solid rgba(0, 184, 212, 0.1)',
          borderRadius: '6px',
          zIndex: 1
        }}
      >
        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box 
            sx={{ 
              display: 'flex', 
              gap: 1, 
              backgroundColor: 'rgba(0, 184, 212, 0.05)', 
              borderRadius: '4px',
              p: 0.5
            }}
          >
            <Tooltip title="Go to root directory">
              <IconButton 
                size="small" 
                onClick={() => handleNavigate('/', 'Root')}
                color="primary"
                disabled={currentPath === '/'}
              >
                <HomeIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Go up one level">
              <span>
                <IconButton 
                  size="small" 
                  onClick={handleGoUp}
                  disabled={currentPath === '/'}
                  color="primary"
                >
                  <ArrowUpIcon fontSize="small" />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Refresh current directory">
              <IconButton 
                size="small" 
                onClick={() => fetchDirectory(currentPath)}
                color="primary"
              >
                <RefreshIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Divider orientation="vertical" flexItem />
            <Tooltip title="Hide browser">
              <IconButton 
                size="small" 
                onClick={() => setIsCollapsed(true)}
                color="default"
              >
                <HideIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box sx={{ 
            maxWidth: '70%', 
            overflow: 'hidden', 
            backgroundColor: 'rgba(0, 0, 0, 0.03)',
            borderRadius: '4px',
            p: 0.5,
            pl: 1.5,
            pr: 1.5
          }}>
            <Breadcrumbs separator={<NavigateIcon fontSize="small" color="primary" sx={{ opacity: 0.5 }} />}>
              {pathHistory.map((item, index) => (
                <Typography
                  key={index}
                  onClick={() => handleNavigate(item.path, item.name)}
                  sx={{ 
                    cursor: 'pointer', 
                    fontSize: '0.875rem',
                    color: index === pathHistory.length - 1 ? 'primary.main' : 'text.secondary',
                    '&:hover': { textDecoration: 'underline' },
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  {index === 0 && <LockIcon fontSize="small" sx={{ mr: 0.5, fontSize: '0.9rem' }} />}
                  {item.name}
                </Typography>
              ))}
            </Breadcrumbs>
          </Box>
        </Box>
        
        {/* Search Box */}
        <Box sx={{ mb: 2 }}>
          <Input
            placeholder="Search files and directories..."
            value={searchQuery}
            onChange={handleSearch}
            prefix={<SearchOutlined style={{ color: '#00b8d4' }} />}
            allowClear
            style={{ 
              borderColor: 'rgba(0, 184, 212, 0.3)',
              borderRadius: '4px'
            }}
          />
        </Box>
        
        <Box sx={{ flex: 1, borderRadius: 1, overflow: 'hidden' }}>
          <Table 
            columns={columns}
            dataSource={filteredFileList}
            rowKey="path"
            loading={loading}
            pagination={false}
            scroll={{ y: height - 140 }} // Reduced offset from 160 to 140 to show more content
            style={{ flex: 1, height: '100%' }}
            className="left-aligned-table"
            onRow={(record) => ({
              onDoubleClick: () => handleRowDoubleClick(record),
              style: { cursor: 'pointer' }
            })}
            rowClassName={() => 'table-row-hover'}
            locale={{ 
              emptyText: searchQuery 
                ? 'No matches found. Try a different search.' 
                : 'This folder is empty.' 
            }}
          />
        </Box>
      </Paper>
    </Resizable>
  );
};

export default FtpBrowser; 