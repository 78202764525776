import React, { useState, useEffect } from 'react';
import { 
  Table, 
  Button, 
  Card, 
  Space, 
  Typography, 
  Tag, 
  Input, 
  Modal, 
  Form, 
  Select, 
  message, 
  Tooltip, 
  Spin,
  Popconfirm,
  Alert
} from 'antd';
import { 
  UserAddOutlined, 
  KeyOutlined, 
  CopyOutlined,
  SearchOutlined,
  ReloadOutlined,
  UserSwitchOutlined,
  LockOutlined,
  UserOutlined
} from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import './UserManagementPage.css';

const { Title, Text, Paragraph } = Typography;
const { Option } = Select;

const UserManagementPage = ({ showNotification }) => {
  const { user } = useAuth0();
  
  // State variables
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [permissionsChecked, setPermissionsChecked] = useState(false);
  const [userSearch, setUserSearch] = useState('');
  
  // Modals state
  const [createUserModalVisible, setCreateUserModalVisible] = useState(false);
  const [changeRoleModalVisible, setChangeRoleModalVisible] = useState(false);
  const [resetPasswordModalVisible, setResetPasswordModalVisible] = useState(false);
  const [resetPasswordLink, setResetPasswordLink] = useState('');
  
  // Selected user for operations
  const [selectedUser, setSelectedUser] = useState(null);
  
  // Form references
  const [createUserForm] = Form.useForm();
  const [changeRoleForm] = Form.useForm();
  
  // Check if the current user has admin permissions
  useEffect(() => {
    const checkAdminPermission = async () => {
      if (!user || !user.email) return;
      
      try {
        setLoading(true);
        const REACT_APP_BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT || 'http://localhost:3001';
        
        const response = await fetch(`${REACT_APP_BACKEND_ENDPOINT}/api/check-admin?email=${encodeURIComponent(user.email)}`);
        
        if (!response.ok) {
          throw new Error(`Failed to check permissions: ${response.status}`);
        }
        
        const data = await response.json();
        setIsAdmin(data.isAdmin);
        setPermissionsChecked(true);
        
        if (data.isAdmin) {
          // Fetch users if admin
          fetchUsers();
        } else {
          if (showNotification) {
            showNotification('error', 'Access Denied', 'You do not have permission to manage users.');
          }
        }
      } catch (error) {
        console.error('Error checking admin permission:', error);
        setPermissionsChecked(true);
        setIsAdmin(false);
        if (showNotification) {
          showNotification('error', 'Error', `Failed to check permissions: ${error.message}`);
        }
      } finally {
        setLoading(false);
      }
    };
    
    checkAdminPermission();
  }, [user, showNotification]);
  
  // Fetch users from the API
  const fetchUsers = async () => {
    try {
      setLoading(true);
      const REACT_APP_BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT || 'http://localhost:3001';
      
      const response = await fetch(`${REACT_APP_BACKEND_ENDPOINT}/api/users`, {
        headers: {
          'X-User-Email': user?.email || ''
        }
      });
      
      if (!response.ok) {
        throw new Error(`Failed to fetch users: ${response.status}`);
      }
      
      const data = await response.json();
      
      // Process users data
      const processedUsers = data.users.map(u => ({
        ...u,
        key: u.user_id,
        lastLogin: u.last_login ? moment(u.last_login).format('YYYY-MM-DD HH:mm') : 'Never',
        created: moment(u.created_at).format('YYYY-MM-DD')
      }));
      
      setUsers(processedUsers);
      
      if (showNotification) {
        showNotification('success', 'Users Loaded', `Retrieved ${processedUsers.length} users`);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      if (showNotification) {
        showNotification('error', 'Error', `Failed to fetch users: ${error.message}`);
      }
    } finally {
      setLoading(false);
    }
  };
  
  // Handle user creation
  const handleCreateUser = async (values) => {
    try {
      console.log('Creating user with values:', values); // Debug log
      console.log('Role selection value:', values.role); // Specific debug for role
      setLoading(true);
      const REACT_APP_BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT || 'http://localhost:3001';
      
      const response = await fetch(`${REACT_APP_BACKEND_ENDPOINT}/api/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-User-Email': user?.email || ''
        },
        body: JSON.stringify({
          email: values.email,
          name: values.name,
          password: values.password,
          role: values.role
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Failed to create user: ${response.status}`);
      }
      
      const data = await response.json();
      
      // Show success message and reset form
      if (showNotification) {
        showNotification('success', 'User Created', `User ${values.name} created successfully`);
      }
      
      createUserForm.resetFields();
      setCreateUserModalVisible(false);
      
      // Show password reset link
      setResetPasswordLink(data.password_reset_link);
      setResetPasswordModalVisible(true);
      
      // Refresh user list
      fetchUsers();
    } catch (error) {
      console.error('Error creating user:', error);
      if (showNotification) {
        showNotification('error', 'Error', `Failed to create user: ${error.message}`);
      }
    } finally {
      setLoading(false);
    }
  };
  
  // Handle role change
  const handleRoleChange = async (values) => {
    try {
      if (!selectedUser) return;
      
      setLoading(true);
      const REACT_APP_BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT || 'http://localhost:3001';
      
      const response = await fetch(`${REACT_APP_BACKEND_ENDPOINT}/api/users/${selectedUser.user_id}/role`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-User-Email': user?.email || ''
        },
        body: JSON.stringify({
          email: selectedUser.email,
          role: values.role
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Failed to update role: ${response.status}`);
      }
      
      await response.json();
      
      // Show success message
      if (showNotification) {
        showNotification('success', 'Role Updated', `User role updated to ${values.role}`);
      }
      
      setChangeRoleModalVisible(false);
      
      // Refresh user list
      fetchUsers();
    } catch (error) {
      console.error('Error updating role:', error);
      if (showNotification) {
        showNotification('error', 'Error', `Failed to update role: ${error.message}`);
      }
    } finally {
      setLoading(false);
    }
  };
  
  // Handle password reset
  const handlePasswordReset = async () => {
    try {
      if (!selectedUser) return;
      
      setLoading(true);
      const REACT_APP_BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT || 'http://localhost:3001';
      
      const response = await fetch(`${REACT_APP_BACKEND_ENDPOINT}/api/users/${selectedUser.user_id}/password-reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-User-Email': user?.email || ''
        },
        body: JSON.stringify({
          email: selectedUser.email
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Failed to create password reset: ${response.status}`);
      }
      
      const data = await response.json();
      
      // Show password reset link
      setResetPasswordLink(data.password_reset_link);
      setResetPasswordModalVisible(true);
      
      // Show success message
      if (showNotification) {
        showNotification('success', 'Password Reset', `Password reset link generated for ${selectedUser.name}`);
      }
    } catch (error) {
      console.error('Error creating password reset:', error);
      if (showNotification) {
        showNotification('error', 'Error', `Failed to create password reset: ${error.message}`);
      }
    } finally {
      setLoading(false);
    }
  };
  
  // Open change role modal
  const showChangeRoleModal = (user) => {
    console.log('Opening change role modal for:', user.name);
    setSelectedUser(user);
    changeRoleForm.setFieldsValue({
      role: user.role || 'user'
    });
    setChangeRoleModalVisible(true);
  };
  
  const closeChangeRoleModal = () => {
    console.log('Closing change role modal');
    setChangeRoleModalVisible(false);
  };
  
  // Copy reset link to clipboard
  const copyResetLink = () => {
    navigator.clipboard.writeText(resetPasswordLink)
      .then(() => {
        if (showNotification) {
          showNotification('success', 'Copied', 'Password reset link copied to clipboard');
        }
      })
      .catch(err => {
        console.error('Error copying to clipboard:', err);
        if (showNotification) {
          showNotification('error', 'Error', 'Failed to copy to clipboard');
        }
      });
  };
  
  // Filter users based on search
  const filteredUsers = userSearch 
    ? users.filter(u => 
        u.name?.toLowerCase().includes(userSearch.toLowerCase()) || 
        u.email?.toLowerCase().includes(userSearch.toLowerCase()))
    : users;
  
  // Table columns definition
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className="user-name-cell">
          <img 
            src={record.picture} 
            alt={text} 
            className="user-avatar" 
            onError={(e) => {e.target.src = 'https://api.dicebear.com/7.x/initials/svg?seed=' + encodeURIComponent(text)}}
          />
          <span>{text}</span>
        </div>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: email => <a href={`mailto:${email}`}>{email}</a>,
      sorter: (a, b) => a.email.localeCompare(b.email)
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (role, record) => (
        <Tag color={role === 'admin' ? 'green' : 'geekblue'}>
          {typeof role === 'string' ? role.toUpperCase() : 'USER'}
        </Tag>
      ),
      filters: [
        { text: 'Admin', value: 'admin' },
        { text: 'User', value: 'user' }
      ],
      onFilter: (value, record) => record.role === value
    },
    {
      title: 'Created',
      dataIndex: 'created',
      key: 'created',
      sorter: (a, b) => moment(a.created_at).valueOf() - moment(b.created_at).valueOf()
    },
    {
      title: 'Last Login',
      dataIndex: 'lastLogin',
      key: 'lastLogin',
      sorter: (a, b) => moment(a.last_login || 0).valueOf() - moment(b.last_login || 0).valueOf()
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="small">
          <Tooltip title="Change Role">
            <Button 
              icon={<UserSwitchOutlined />} 
              size="small" 
              onClick={() => showChangeRoleModal(record)}
              style={{
                color: '#1890ff',
                borderColor: '#1890ff',
                background: 'rgba(24, 144, 255, 0.1)',
              }}
            />
          </Tooltip>
          <Tooltip title="Reset Password">
            <Button 
              icon={<KeyOutlined />} 
              size="small" 
              danger
              onClick={() => {
                setSelectedUser(record);
                handlePasswordReset();
              }}
            />
          </Tooltip>
        </Space>
      )
    }
  ];
  
  // Check if user is not an admin and permissions are checked
  if (permissionsChecked && !isAdmin) {
    return (
      <div className="access-denied">
        <Card>
          <Title level={4}>Access Denied</Title>
          <Paragraph>
            You do not have permission to manage users. This feature is restricted to administrators.
          </Paragraph>
        </Card>
      </div>
    );
  }

  // Modal state management
  const showCreateUserModal = () => {
    console.log('Opening create user modal');
    createUserForm.resetFields();
    createUserForm.setFieldsValue({ role: 'user' }); // Set default value
    setCreateUserModalVisible(true);
  };

  const closeCreateUserModal = () => {
    console.log('Closing create user modal');
    setCreateUserModalVisible(false);
  };

  return (
    <div className="user-management-page">
      {loading && <Spin size="large" className="page-loader" />}
      
      <div className="users-content">
        <Card 
          title={<Title level={4}>User Management</Title>}
          extra={
            <Space>
              <Input
                placeholder="Search users..."
                prefix={<SearchOutlined />}
                value={userSearch}
                onChange={e => setUserSearch(e.target.value)}
              />
              <Button 
                type="primary" 
                icon={<UserAddOutlined />}
                onClick={showCreateUserModal}
                style={{
                  background: 'linear-gradient(to right, #1890ff, #52c41a)',
                  fontWeight: 'bold',
                  border: 'none'
                }}
              >
                Create User
              </Button>
              <Button 
                icon={<ReloadOutlined />}
                onClick={fetchUsers}
              >
                Refresh
              </Button>
            </Space>
          }
        >
          <Table 
            dataSource={filteredUsers} 
            columns={columns} 
            loading={loading}
            pagination={{ pageSize: 10 }}
            rowKey="user_id"
            scroll={{ x: 'max-content' }}
          />
        </Card>
      </div>
      
      {/* Create User Modal */}
      <Modal
        title="Create New User"
        open={createUserModalVisible}
        onCancel={closeCreateUserModal}
        footer={null}
        width={500}
        style={{ top: 20 }}
        zIndex={1500}
        maskStyle={{ backgroundColor: 'rgba(0,0,0,0.6)' }}
        bodyStyle={{ maxHeight: '70vh', overflow: 'auto', padding: '20px' }}
      >
        <Form
          form={createUserForm}
          layout="vertical"
          onFinish={handleCreateUser}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Please enter the user name' }]}
          >
            <Input prefix={<UserOutlined />} placeholder="Full Name" />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: 'Please enter the email address' },
              { type: 'email', message: 'Please enter a valid email address' }
            ]}
          >
            <Input placeholder="Email Address" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              { required: true, message: 'Please enter a password' },
              { min: 8, message: 'Password must be at least 8 characters' }
            ]}
          >
            <Input.Password 
              prefix={<LockOutlined />} 
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item
            name="role"
            label={<span style={{ fontWeight: 'bold', color: '#1890ff' }}>Role</span>}
            rules={[{ required: true, message: 'Please select a role' }]}
            className="role-select"
            tooltip="Select new role for this user"
          >
            <Select
              style={{ 
                width: '100%',
                border: '1px solid #d9d9d9',
                borderRadius: '4px'
              }}
              placeholder="Select a role"
              popupClassName="role-select-dropdown"
              getPopupContainer={trigger => trigger.parentElement}
              dropdownStyle={{ zIndex: 2000 }}
              optionLabelProp="label"
            >
              <Option value="user" label="Regular User">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <UserOutlined style={{ marginRight: 8 }} />
                  <span>Regular User</span>
                </div>
              </Option>
              <Option value="admin" label="Administrator">
                <div style={{ display: 'flex', alignItems: 'center', color: '#52c41a' }}>
                  <UserSwitchOutlined style={{ marginRight: 8 }} />
                  <span style={{ fontWeight: 'bold' }}>Administrator</span>
                </div>
              </Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <div className="form-actions">
              <Button onClick={closeCreateUserModal}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Create User
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      
      {/* Change Role Modal */}
      <Modal
        title="Change User Role"
        open={changeRoleModalVisible}
        onCancel={closeChangeRoleModal}
        footer={null}
        width={500}
        style={{ top: 20 }}
        zIndex={1500}
        maskStyle={{ backgroundColor: 'rgba(0,0,0,0.6)' }}
        bodyStyle={{ maxHeight: '70vh', overflow: 'auto', padding: '20px' }}
      >
        {selectedUser && (
          <Form
            form={changeRoleForm}
            layout="vertical"
            onFinish={handleRoleChange}
          >
            <Paragraph>
              Change role for <strong>{selectedUser.name}</strong> ({selectedUser.email})
            </Paragraph>
            <Form.Item
              name="role"
              label={<span style={{ fontWeight: 'bold', color: '#1890ff' }}>Role</span>}
              rules={[{ required: true, message: 'Please select a role' }]}
              className="role-select"
              tooltip="Select new role for this user"
            >
              <Select
                style={{ 
                  width: '100%',
                  border: '1px solid #d9d9d9',
                  borderRadius: '4px'
                }}
                placeholder="Select a role"
                popupClassName="role-select-dropdown"
                getPopupContainer={trigger => trigger.parentElement}
                dropdownStyle={{ zIndex: 2000 }}
                optionLabelProp="label"
              >
                <Option value="user" label="Regular User">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <UserOutlined style={{ marginRight: 8 }} />
                    <span>Regular User</span>
                  </div>
                </Option>
                <Option value="admin" label="Administrator">
                  <div style={{ display: 'flex', alignItems: 'center', color: '#52c41a' }}>
                    <UserSwitchOutlined style={{ marginRight: 8 }} />
                    <span style={{ fontWeight: 'bold' }}>Administrator</span>
                  </div>
                </Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <div className="form-actions">
                <Button onClick={closeChangeRoleModal}>
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Update Role
                </Button>
              </div>
            </Form.Item>
          </Form>
        )}
      </Modal>
      
      {/* Password Reset Link Modal */}
      <Modal
        title="Password Reset Link"
        open={resetPasswordModalVisible}
        onCancel={() => setResetPasswordModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setResetPasswordModalVisible(false)}>
            Close
          </Button>,
          <Button 
            key="copy" 
            type="primary" 
            icon={<CopyOutlined />} 
            onClick={copyResetLink}
          >
            Copy Link
          </Button>
        ]}
      >
        <div className="password-reset-link">
          <Paragraph>
            Send this password reset link to the user:
          </Paragraph>
          <Input.TextArea
            value={resetPasswordLink}
            readOnly
            autoSize={{ minRows: 3, maxRows: 6 }}
            style={{ marginBottom: 16 }}
          />
          <Alert
            message="Important"
            description="This link is valid for 24 hours. It should be sent securely to the user."
            type="warning"
            showIcon
          />
        </div>
      </Modal>
    </div>
  );
};

export default UserManagementPage; 