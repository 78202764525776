import React, { useEffect } from 'react';
import { Modal, Button } from 'antd';
import { DiffEditor } from '@monaco-editor/react';

const RollbackModal = ({ 
  visible, 
  onClose, 
  originalContent, 
  currentContent, 
  onRollback 
}) => {
  // Force a window resize event after the modal is fully visible
  // This helps Monaco editor recalculate its dimensions correctly
  useEffect(() => {
    if (visible) {
      const timer = setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [visible]);

  return (
    <Modal
      title="Compare Changes"
      open={visible}
      onCancel={onClose}
      width="90vw"
      style={{ 
        top: 20,
        maxWidth: '1800px'
      }}
      bodyStyle={{
        height: 'calc(90vh - 150px)',
        padding: '20px 0 10px 0', // Added more top padding
        overflow: 'hidden',
        position: 'relative' // Added to ensure positioning context
      }}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button 
          key="rollback" 
          type="primary" 
          danger 
          onClick={onRollback}
        >
          Roll Back to Original
        </Button>
      ]}
      className="monaco-diff-modal" // Added class for CSS targeting
    >
      <div style={{ 
        height: '100%', 
        width: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative', // For proper child positioning
        padding: '0 10px' // Add horizontal padding
      }}>
        <DiffEditor
          height="100%"
          width="100%"
          language="html"
          original={originalContent}
          modified={currentContent}
          theme="diffTheme"
          beforeMount={(monaco) => {
            // Configure Monaco editor before it mounts
            monaco.editor.defineTheme('diffTheme', {
              base: 'vs',
              inherit: true,
              rules: [],
              colors: {
                'editor.background': '#ffffff',
                'editor.lineHighlightBackground': '#f0f0f0',
                'editorLineNumber.foreground': '#666666',
                'editorGutter.background': '#f5f5f5'
              }
            });
            // These settings help with proper layout
            monaco.editor.onDidCreateEditor(editor => {
              setTimeout(() => {
                editor.layout();
                window.dispatchEvent(new Event('resize'));
              }, 100);
            });
          }}
          options={{
            readOnly: true,
            renderSideBySide: true,
            minimap: { enabled: false },
            fontSize: 14,
            lineNumbers: 'on',
            scrollBeyondLastLine: false,
            wordWrap: 'on',
            automaticLayout: true,
            fixedOverflowWidgets: true, // Fix for widgets that might overflow
            folding: true,
            lineDecorationsWidth: 10,
            lineNumbersMinChars: 3,
            renderOverviewRuler: false, // Disable overview ruler to save space
            // Disable suggestions and intellisense
            quickSuggestions: false,
            suggestOnTriggerCharacters: false,
            acceptSuggestionOnEnter: "off",
            tabCompletion: "off",
            wordBasedSuggestions: false,
            parameterHints: { enabled: false },
            suggest: { enabled: false }
          }}
          className="monaco-diff-editor" // Add class for CSS targeting
        />
      </div>
    </Modal>
  );
};

export default RollbackModal; 