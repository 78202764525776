import React, { useState, useEffect, useRef } from 'react';
import { 
  Table, 
  Card, 
  DatePicker, 
  Select, 
  Input, 
  Button, 
  Space, 
  Spin, 
  Tabs, 
  Tooltip,
  Typography,
  Tag,
  Alert,
  Col,
  Row,
  Statistic,
  List,
  Avatar,
  Modal
} from 'antd';
import { 
  DownloadOutlined, 
  FileTextOutlined, 
  ReloadOutlined, 
  UserOutlined,
  CloudUploadOutlined,
  EditOutlined,
  PieChartOutlined,
  BarChartOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
  FileOutlined,
  ToolOutlined,
  CodeOutlined
} from '@ant-design/icons';
import { 
  LineChart, 
  Line, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip as RechartsTooltip, 
  Legend, 
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  ComposedChart
} from 'recharts';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import './LogsPage.css';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { TabPane } = Tabs;
const { Title, Text } = Typography;

// Define color constants for charts
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];
const OPERATION_COLORS = {
  'edit': '#0088FE',
  'upload': '#00C49F',
  'download': '#FFBB28',
  'delete': '#FF8042',
  'lock': '#8884d8',
  'unlock': '#82ca9d'
};
const STATUS_COLORS = {
  'success': '#52c41a',
  'failure': '#f5222d'
};

const LogsPage = ({ showNotification }) => {
  const { user } = useAuth0();
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState(null);
  const [formFilters, setFormFilters] = useState({
    from_date: moment().subtract(30, 'days').startOf('day').format('YYYY-MM-DD'),
    to_date: moment().endOf('day').format('YYYY-MM-DD'),
    operation_type: '',
    file_path: '',
    status: ''
  });
  
  // Permission state
  const [isAdmin, setIsAdmin] = useState(false);
  const [permissionsChecked, setPermissionsChecked] = useState(false);
  const [permissionError, setPermissionError] = useState(null);
  
  // Global filters state
  const [filters, setFilters] = useState({
    user_id: '',
    operation_type: '',
    file_path: '',
    status: ''
  });
  
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });
  const [activeTab, setActiveTab] = useState('1');
  
  // Format logs data for time series chart
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  
  // Ref for downloading CSV
  const csvLinkRef = useRef(null);
  
  // State for log detail modal
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [selectedLog, setSelectedLog] = useState(null);
  
  // Ref to track the last notification time (to prevent duplicates)
  const lastNotificationTimeRef = useRef(0);
  
  // Memoize the date range value to prevent unnecessary re-renders
  const dateRangeValue = React.useMemo(() => {
    const from = moment(formFilters.from_date);
    const to = moment(formFilters.to_date);
    return [from.isValid() ? from : null, to.isValid() ? to : null];
  }, [formFilters.from_date, formFilters.to_date]);

  // Memoize the date range handler to prevent recreation on each render
  const handleDateRangeChange = React.useCallback((dates) => {
    if (!dates || !dates[0] || !dates[1]) {
      return;
    }

    const from = moment(dates[0]);
    const to = moment(dates[1]);

    // Validate dates before updating state
    if (!from.isValid() || !to.isValid()) {
      return;
    }

    // Ensure dates are within reasonable range
    if (from.year() < 1970 || to.year() > 2100) {
      return;
    }

    setFormFilters(prev => ({
      ...prev,
      from_date: from.format('YYYY-MM-DD'),
      to_date: to.format('YYYY-MM-DD')
    }));
  }, []);
  
  // Helper function to show notifications with deduplication
  const showDedupedNotification = (type, message, description) => {
    if (!showNotification) return;
    
    const now = Date.now();
    // Only show notification if at least 2 seconds have passed since the last one with the same message
    if (now - lastNotificationTimeRef.current > 2000) {
      showNotification(type, message, description);
      lastNotificationTimeRef.current = now;
    }
  };
  
  // Check if the current user has admin permissions
  useEffect(() => {
    const checkAdminPermission = async () => {
      if (!user || !user.email) return;
      
      try {
        setLoading(true);
        const REACT_APP_BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT || 'http://localhost:3001';
        
        console.log(`Checking admin permissions for ${user.email}`);
        const response = await fetch(`${REACT_APP_BACKEND_ENDPOINT}/api/check-admin?email=${encodeURIComponent(user.email)}`);
        
        if (!response.ok) {
          throw new Error(`Failed to check permissions: ${response.status}`);
        }
        
        const data = await response.json();
        console.log('Admin permission check result:', data);
        
        setIsAdmin(data.isAdmin);
        setPermissionsChecked(true);
        
        if (!data.isAdmin) {
          setPermissionError('You do not have permission to view logs. This feature is restricted to administrators.');
          if (showNotification) {
            showDedupedNotification('error', 'Access Denied', 'You do not have permission to view logs.');
          }
        } else {
          // Only fetch logs if user is an admin
          fetchLogs(1, pagination.pageSize);
          fetchSummary();
        }
      } catch (error) {
        console.error('Error checking admin permission:', error);
        setPermissionError(`Error checking permissions: ${error.message}`);
        setPermissionsChecked(true);
        setIsAdmin(false);
      } finally {
        setLoading(false);
      }
    };
    
    checkAdminPermission();
  }, [user, showNotification]);
  
  // Calculate time series data from logs
  const calculateTimeSeriesData = (logsData) => {
    if (!logsData || logsData.length === 0) return [];
    
    // Create date-based aggregation of operations
    const dateGrouped = {};
    logsData.forEach(log => {
      const date = moment(log.timestamp).format('YYYY-MM-DD');
      if (!dateGrouped[date]) {
        dateGrouped[date] = {
          date,
          edit: 0,
          upload: 0,
          download: 0,
          delete: 0,
          lock: 0,
          unlock: 0
        };
      }
      
      if (log.operation_type) {
        dateGrouped[date][log.operation_type] = (dateGrouped[date][log.operation_type] || 0) + 1;
      }
    });
    
    // Convert to array sorted by date
    return Object.values(dateGrouped).sort((a, b) => 
      moment(a.date).valueOf() - moment(b.date).valueOf()
    );
  };

  // Fetch logs from the backend
  const fetchLogs = async (page = 1, pageSize = 10) => {
    console.log("Fetching logs with params:", { page, pageSize, filters: formFilters });
    setLoading(true);
    try {
      const REACT_APP_BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT || 'http://localhost:3001';
      
      // Build query parameters
      const params = new URLSearchParams();
      params.append('page', page);
      params.append('pageSize', pageSize);
      
      // Add user email for permission check
      if (user && user.email) {
        params.append('userEmail', user.email);
      }
      
      if (formFilters.user_id) params.append('user_id', formFilters.user_id);
      if (formFilters.operation_type) params.append('operation_type', formFilters.operation_type);
      if (formFilters.file_path) params.append('file_path', formFilters.file_path);
      if (formFilters.status) params.append('status', formFilters.status);
      
      // Format dates properly for the API
      if (formFilters.from_date && moment(formFilters.from_date).isValid()) {
        const fromDate = moment(formFilters.from_date).startOf('day').utc().format();
        params.append('from_date', fromDate);
      }
      if (formFilters.to_date && moment(formFilters.to_date).isValid()) {
        const toDate = moment(formFilters.to_date).endOf('day').utc().format();
        params.append('to_date', toDate);
      }

      const apiUrl = `${REACT_APP_BACKEND_ENDPOINT}/api/logs?${params.toString()}`;
      console.log("API URL:", apiUrl);
      
      console.log("Starting fetch request to:", apiUrl);
      
      const response = await fetch(apiUrl, {
        headers: {
          'X-User-Email': user?.email || '' // Add user email in headers as well
        }
      });
      
      console.log("Fetch response received:", response.status, response.statusText);
      
      if (!response.ok) {
        if (response.status === 403) {
          throw new Error('Access denied: You do not have permission to view logs');
        }
        throw new Error(`API error: ${response.status} ${response.statusText}`);
      }
      
      const data = await response.json();
      console.log("API Response parsed:", data);
      
      if (!data || !Array.isArray(data.logs)) {
        console.error("API returned invalid data format:", data);
        throw new Error("Invalid data format received from API");
      }
      
      // Add key property to each log entry for Table component
      const logsWithKeys = data.logs.map((log, index) => ({
        ...log,
        key: log.id || index
      }));
      
      console.log("Processed logs:", logsWithKeys);
      setLogs(logsWithKeys);
      setPagination({
        current: data.pagination?.page || 1,
        pageSize: data.pagination?.pageSize || 10,
        total: data.pagination?.total || logsWithKeys.length
      });
      
      // Calculate time series data here
      setTimeSeriesData(calculateTimeSeriesData(logsWithKeys));
    } catch (error) {
      console.error('Error fetching logs:', error);
      alert('Error fetching logs: ' + error.message);
      if (showNotification) {
        showDedupedNotification('error', 'Error', 'Failed to fetch logs: ' + error.message);
      }
      // Set empty logs to avoid showing stale data
      setLogs([]);
    } finally {
      setLoading(false);
    }
  };

  // Fetch summary data
  const fetchSummary = async () => {
    console.log("Fetching summary data with date range:", [moment(formFilters.from_date), moment(formFilters.to_date)]);
    setLoading(true);
    try {
      const REACT_APP_BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT || 'http://localhost:3001';
      
      // Build query parameters for date range
      const params = new URLSearchParams();
      
      // Add user email for permission check
      if (user && user.email) {
        params.append('userEmail', user.email);
      }
      
      if (formFilters.from_date && moment(formFilters.from_date).isValid()) {
        params.append('from_date', formFilters.from_date);
      }
      
      if (formFilters.to_date && moment(formFilters.to_date).isValid()) {
        params.append('to_date', formFilters.to_date);
      }

      const apiUrl = `${REACT_APP_BACKEND_ENDPOINT}/api/logs/summary?${params.toString()}`;
      
      const response = await fetch(apiUrl, {
        headers: {
          'X-User-Email': user?.email || '' // Add user email in headers as well
        }
      });
      
      if (!response.ok) {
        if (response.status === 403) {
          throw new Error('Access denied: You do not have permission to view logs');
        }
        throw new Error(`API error: ${response.status} ${response.statusText}`);
      }
      
      const data = await response.json();
      console.log("Summary API response data:", data);
      
      if (data && data.success && data.summary) {
        console.log("Setting summary data:", data.summary);
        setSummary(data.summary);
      } else {
        console.error("Invalid summary data format:", data);
        throw new Error("Invalid summary data format");
      }
    } catch (error) {
      console.error('Error fetching summary:', error);
      alert('Error fetching summary: ' + error.message);
      if (showNotification) {
        showDedupedNotification('error', 'Error', 'Failed to fetch activity summary: ' + error.message);
      }
      // Reset summary to avoid showing stale data
      setSummary(null);
    } finally {
      setLoading(false);
    }
  };

  // Handle table pagination change - make sure to prevent the event from propagating
  const handleTableChange = (paginationInfo, filters, sorter, extra) => {
    console.log('Table pagination changed:', paginationInfo);
    
    // Stop event propagation to prevent interfering with sidebar
    if (extra && extra.currentTarget) {
      extra.currentTarget.stopPropagation();
    }
    
    // Update pagination state
    setPagination({
      current: paginationInfo.current,
      pageSize: paginationInfo.pageSize,
      total: paginationInfo.total || logs.length
    });
    
    // Fetch logs for the new page
    fetchLogs(paginationInfo.current, paginationInfo.pageSize);
  };

  // Handle form input changes
  const handleFormChange = (field, value) => {
    if ((field === 'from_date' || field === 'to_date') && value) {
      // Ensure dates are properly formatted
      const formattedDate = moment(value).format('YYYY-MM-DD');
      setFormFilters(prev => ({
        ...prev,
        [field]: formattedDate
      }));
    } else {
      setFormFilters(prev => ({
        ...prev,
        [field]: value
      }));
    }
  };

  // Apply filters using the form values
  const applyFilters = () => {
    // No need to update dateRange here since it's already updated in the onChange handler
    
    // Update the global filters state from form values
    setFilters({
      operation_type: formFilters.operation_type,
      file_path: formFilters.file_path,
      status: formFilters.status
    });
    
    // Fetch logs with the new filters
    fetchLogs(1, pagination.pageSize);
    
    // Fetch summary if on the Analytics tab
    if (activeTab === '2') {
      fetchSummary();
    }
  };
  
  // Reset all filters to default values
  const resetFilters = () => {
    setFormFilters({
      from_date: moment().subtract(30, 'days').startOf('day').format('YYYY-MM-DD'),
      to_date: moment().endOf('day').format('YYYY-MM-DD'),
      operation_type: '',
      file_path: '',
      status: ''
    });
    
    // Reset global state
    setFilters({
      user_id: '',
      operation_type: '',
      file_path: '',
      status: ''
    });
    
    // Fetch logs with reset filters
    fetchLogs(1, pagination.pageSize);
  };

  // Prepare data for CSV export
  const prepareCSVData = () => {
    // Create CSV headers with editing time added
    const headers = [
      'Timestamp', 
      'User', 
      'Email', 
      'Operation', 
      'File Path', 
      'File Name', 
      'File Type',
      'File Size', 
      'Status', 
      'Processing Time (ms)',
      'Time to Finish Edit (ms)', // New column for editing time
      'Lines Added',
      'Lines Removed',
      'Characters Changed',
      'Browser/Tool'
    ];
    
    // Map log data to CSV rows with enhanced information
    const rows = logs.map(log => {
      // Get editing time either from direct field or metadata
      let editingTime = log.editing_time || 0;
      if (!editingTime && log.metadata && log.metadata.editing_time) {
        editingTime = log.metadata.editing_time;
      }
      
      // Get changes summary information
      let linesAdded = 0;
      let linesRemoved = 0;
      let charsChanged = 0;
      
      if (log.changes_summary) {
        try {
          const changes = typeof log.changes_summary === 'string' 
            ? JSON.parse(log.changes_summary) 
            : log.changes_summary;
            
          linesAdded = changes.linesAdded || 0;
          linesRemoved = changes.linesRemoved || 0;
          charsChanged = changes.charactersChanged || 0;
        } catch (e) {
          console.error('Error parsing changes summary:', e);
        }
      }
      
      // Get browser/tool information
      let browserTool = 'Unknown';
      if (log.metadata) {
        try {
          const metadata = typeof log.metadata === 'string' 
            ? JSON.parse(log.metadata) 
            : log.metadata;
            
          browserTool = metadata.toolUsed || metadata.browser || 'Unknown';
        } catch (e) {
          console.error('Error parsing metadata:', e);
        }
      }
      
      return [
        moment(log.timestamp).format('YYYY-MM-DD HH:mm:ss'),
        log.user_name || 'Unknown',
        log.user_email || 'Unknown',
        log.operation_type || 'Unknown',
        log.file_path || 'N/A',
        log.file_name || 'N/A',
        log.file_type || 'N/A',
        log.file_size || 0,
        log.status || 'Unknown',
        log.processing_time || 0,
        editingTime,
        linesAdded,
        linesRemoved,
        charsChanged,
        browserTool
      ];
    });
    
    // Combine headers and rows
    return [headers, ...rows];
  };

  // Download logs as CSV
  const downloadCSV = () => {
    const csvData = prepareCSVData();
    
    // Replace any commas in fields with semicolons to avoid CSV parsing issues
    const escapedData = csvData.map(row => 
      row.map(field => {
        // Convert to string and escape if needed
        const strField = String(field || '');
        return strField.includes(',') ? `"${strField}"` : strField;
      })
    );
    
    const csvContent = escapedData.map(row => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    
    // Create temporary link and click it
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `ftp_logs_${moment().format('YYYY-MM-DD')}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
    // Cleanup
    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 100);
  };

  // Format time for display
  const formatTime = (timestamp) => {
    return moment(timestamp).format('YYYY-MM-DD HH:mm:ss');
  };

  // Format editing time properly
  const formatEditingTime = (milliseconds) => {
    if (!milliseconds) return 'N/A';
    
    // For very short times (less than a second)
    if (milliseconds < 1000) {
      return `${milliseconds}ms`;
    }
    
    // For times in seconds (less than a minute)
    if (milliseconds < 60000) {
      return `${(milliseconds / 1000).toFixed(1)}s`;
    }
    
    // For times in minutes and seconds
    const minutes = Math.floor(milliseconds / 60000);
    const seconds = ((milliseconds % 60000) / 1000).toFixed(0);
    return `${minutes}m ${seconds}s`;
  };

  // Define table columns
  const columns = [
    {
      title: 'Time',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: timestamp => formatTime(timestamp),
      sorter: (a, b) => moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf(),
      width: 170,
    },
    {
      title: 'User',
      dataIndex: 'user_name',
      key: 'user_name',
      render: (userName, record) => (
        <Tooltip title={record.user_email || 'Unknown email'}>
          <span>{userName || 'Unknown'}</span>
        </Tooltip>
      ),
      width: 150,
    },
    {
      title: 'Operation',
      dataIndex: 'operation_type',
      key: 'operation_type',
      render: operation => {
        let icon = <FileTextOutlined />;
        let color = OPERATION_COLORS[operation] || '#1890ff';
        
        if (operation === 'edit') icon = <EditOutlined />;
        else if (operation === 'upload') icon = <CloudUploadOutlined />;
        
        return (
          <Tag color={color} icon={icon}>
            {typeof operation === 'string' ? operation.toUpperCase() : 'UNKNOWN'}
          </Tag>
        );
      },
      width: 120,
      filters: [
        { text: 'Edit', value: 'edit' },
        { text: 'Upload', value: 'upload' },
      ],
      onFilter: (value, record) => record.operation_type === value,
    },
    {
      title: 'Tool',
      dataIndex: ['metadata', 'toolUsed'],
      key: 'tool',
      render: tool => {
        const toolName = tool || 'Unknown';
        let icon = <ToolOutlined />;
        let color = '#1890ff';
        
        if (toolName.includes('Developer')) {
          icon = <CodeOutlined />;
          color = '#722ed1';
        } else if (toolName.includes('Editor')) {
          icon = <EditOutlined />;
          color = '#52c41a';
        } else if (toolName.includes('Upload')) {
          icon = <CloudUploadOutlined />;
          color = '#fa8c16';
        }
        
        return (
          <Tag color={color} icon={icon}>
            {toolName}
          </Tag>
        );
      },
      width: 150,
      filters: [
        { text: 'EditorPage', value: 'EditorPage' },
        { text: 'DeveloperPage', value: 'DeveloperPage' },
        { text: 'CustomUploadTool', value: 'CustomUploadTool' },
      ],
      onFilter: (value, record) => record.metadata?.toolUsed === value,
    },
    {
      title: 'File',
      dataIndex: 'file_path',
      key: 'file_path',
      render: (filePath, record) => (
        <Tooltip title={filePath}>
          <span>{record.file_name || 'Unknown'}</span>
        </Tooltip>
      ),
      ellipsis: true,
    },
    {
      title: 'Size',
      dataIndex: 'file_size',
      key: 'file_size',
      render: size => {
        if (!size) return 'N/A';
        
        // Format size to KB, MB etc.
        if (size < 1024) return `${size} bytes`;
        else if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
        else return `${(size / (1024 * 1024)).toFixed(2)} MB`;
      },
      width: 100,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: status => {
        const color = status === 'success' ? '#52c41a' : '#f5222d';
        const icon = status === 'success' ? <CheckCircleOutlined /> : <CloseCircleOutlined />;
        
        return (
          <Tag color={color} icon={icon}>
            {typeof status === 'string' ? status.toUpperCase() : 'UNKNOWN'}
          </Tag>
        );
      },
      width: 110,
      filters: [
        { text: 'Success', value: 'success' },
        { text: 'Failure', value: 'failure' },
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      title: 'Processing Time',
      dataIndex: 'processing_time',
      key: 'processing_time',
      render: time => {
        if (!time) return 'N/A';
        
        // Format time to ms, s, or min
        if (time < 1000) return `${time}ms`;
        else if (time < 60000) return `${(time / 1000).toFixed(1)}s`;
        else return `${(time / 60000).toFixed(1)}m`;
      },
      width: 130,
      sorter: (a, b) => (a.processing_time || 0) - (b.processing_time || 0)
    },
    {
      title: 'Changes',
      dataIndex: ['changes_summary', 'charactersChanged'],
      key: 'changes',
      render: (chars, record) => {
        if (!record.changes_summary) return 'N/A';
        
        const { linesAdded, linesRemoved, charactersChanged } = record.changes_summary;
        
        if (linesAdded || linesRemoved) {
          return (
            <Tooltip title={`Lines: +${linesAdded || 0}/-${linesRemoved || 0}, Chars: ${charactersChanged || 0}`}>
              <div>
                {linesAdded > 0 && <Tag color="green">+{linesAdded}</Tag>}
                {linesRemoved > 0 && <Tag color="red">-{linesRemoved}</Tag>}
              </div>
            </Tooltip>
          );
        }
        
        return chars ? `${chars} chars` : 'N/A';
      },
      width: 120,
    },
    {
      title: 'Editing Time',
      dataIndex: 'editing_time',
      key: 'editing_time',
      render: (time) => formatEditingTime(time),
      responsive: ['md'],
    },
  ];
  
  // Prepare data for pie charts
  const preparePieData = () => {
    if (!summary) return { operations: [], status: [] };
    
    const operations = Object.entries(summary.by_operation || {}).map(([name, value]) => ({
      name,
      value
    }));
    
    const status = Object.entries(summary.by_status || {}).map(([name, value]) => ({
      name,
      value
    }));
    
    return { operations, status };
  };
  
  // Prepare data for user activity chart
  const prepareUserData = () => {
    if (!summary) return [];
    
    return Object.values(summary.by_user || {})
      .map(user => ({
        name: user.user_email || 'Unknown',
        value: user.count || 0
      }))
      .sort((a, b) => b.value - a.value)
      .slice(0, 10); // Top 10 users
  };

  // Main useEffect to fetch logs and summary and clean up properly
  useEffect(() => {
    console.log('LogsPage mounted or updated');
    
    // Add chart centering CSS
    const style = document.createElement('style');
    style.textContent = `
      .chart-container {
        width: 100% !important;
        max-width: 900px !important;
        margin: 0 auto !important;
        position: relative !important;
        display: block !important;
        text-align: center !important;
      }
      
      .recharts-wrapper {
        margin: 0 auto !important;
        position: relative !important;
        left: 0 !important;
      }
      
      .recharts-surface {
        display: block !important;
        margin: 0 auto !important;
      }
      
      /* Fix for z-index issues */
      .ant-tabs-tab {
        z-index: 1;
      }
      
      /* Make sure navigation elements have highest z-index */
      .ant-layout-sider, .MuiDrawer-root {
        z-index: 1200 !important;
      }
      
      /* Ensure table pagination is visible */
      .ant-pagination {
        z-index: 50;
        position: relative;
      }
    `;
    document.head.appendChild(style);
    
    // Add event listener to capture and stop propagation of pagination clicks
    const handleClick = (e) => {
      if (e.target.closest('.ant-pagination-item') || 
          e.target.closest('.ant-pagination-prev') || 
          e.target.closest('.ant-pagination-next') ||
          e.target.closest('.ant-pagination-jump-prev') ||
          e.target.closest('.ant-pagination-jump-next')) {
        e.stopPropagation();
      }
    };
    
    document.addEventListener('click', handleClick, true);
    
    return () => {
      // Clean up styles and event listeners
      document.head.removeChild(style);
      document.removeEventListener('click', handleClick, true);
      console.log('LogsPage unmounted - cleanup complete');
    };
  }, []);
  
  // Update time series data when logs change
  useEffect(() => {
    if (logs.length > 0) {
      setTimeSeriesData(calculateTimeSeriesData(logs));
    }
  }, [logs]);

  // Tab change handler - outside of useEffect to avoid update loops
  const handleTabChange = (newTab) => {
    console.log('Tab changed to:', newTab);
    
    // Only update if actually changing tabs
    if (newTab !== activeTab) {
      setActiveTab(newTab);
      
      // Only fetch summary when switching to Dashboard tab
      if (newTab === '1') {
        fetchSummary();
      } else if (newTab === '2') {
        // If switching to Log Entries tab, make sure we have logs
        fetchLogs(pagination.current, pagination.pageSize);
      }
    }
    
    // Ensure event doesn't propagate to parent elements
    if (window.event) {
      window.event.stopPropagation();
    }
  };

  const pieData = preparePieData();
  const userData = prepareUserData();

  // Dashboard component to display key metrics
  const Dashboard = ({ logs, summary }) => {
    // Calculate metrics
    const calculateMetrics = () => {
      if (!logs || logs.length === 0) {
        return {
          totalEdits: 0,
          totalUploads: 0,
          avgProcessingTime: 0,
          editsByUser: {},
          uploadsByUser: {},
          toolUsage: {},
          popularFiles: []
        };
      }

      const metrics = {
        totalEdits: 0,
        totalUploads: 0,
        totalProcessingTime: 0,
        editsByUser: {},
        uploadsByUser: {},
        toolUsage: {},
        fileEditCounts: {},
      };

      logs.forEach(log => {
        // Count by operation type
        if (log.operation_type === 'edit') {
          metrics.totalEdits++;
          
          // Count edits by user
          const userId = log.user_email || log.user_id || 'Unknown';
          metrics.editsByUser[userId] = (metrics.editsByUser[userId] || 0) + 1;
          
          // Count edits by file
          if (log.file_path) {
            metrics.fileEditCounts[log.file_path] = (metrics.fileEditCounts[log.file_path] || 0) + 1;
          }
        } else if (log.operation_type === 'upload') {
          metrics.totalUploads++;
          
          // Count uploads by user
          const userId = log.user_email || log.user_id || 'Unknown';
          metrics.uploadsByUser[userId] = (metrics.uploadsByUser[userId] || 0) + 1;
        }
        
        // Count tool usage
        const tool = log.metadata?.toolUsed || 'Unknown';
        metrics.toolUsage[tool] = (metrics.toolUsage[tool] || 0) + 1;
        
        // Sum processing time
        if (log.processing_time) {
          metrics.totalProcessingTime += log.processing_time;
        }
      });
      
      // Calculate average processing time
      metrics.avgProcessingTime = metrics.totalProcessingTime / logs.length;
      
      // Get most popular files (top 5)
      metrics.popularFiles = Object.entries(metrics.fileEditCounts)
        .map(([file, count]) => ({ file, count }))
        .sort((a, b) => b.count - a.count)
        .slice(0, 5);
      
      return metrics;
    };

    const metrics = calculateMetrics();
    
    // Format the metrics for display
    const formatTime = (ms) => {
      if (ms < 1000) return `${ms}ms`;
      if (ms < 60000) return `${(ms / 1000).toFixed(1)}s`;
      return `${(ms / 60000).toFixed(1)}m`;
    };
    
    // Top users for edits
    const topEditors = Object.entries(metrics.editsByUser)
      .map(([user, count]) => ({ user, count }))
      .sort((a, b) => b.count - a.count)
      .slice(0, 3);
      
    // Tool usage data for chart
    const toolData = Object.entries(metrics.toolUsage)
      .map(([name, value]) => ({ name, value }));

    return (
      <Card title="Dashboard" style={{ marginBottom: 16 }}>
        <div style={{ width: '100%', textAlign: 'center' }}>
          {/* Key metrics */}
          <div style={{ display: 'table', width: '100%', tableLayout: 'fixed', marginBottom: '16px' }}>
            <div style={{ display: 'table-cell', width: '25%', padding: '0 8px' }}>
              <Card className="analytics-summary-card">
                <Statistic 
                  title="Total Edits" 
                  value={metrics.totalEdits} 
                  prefix={<EditOutlined />} 
                />
              </Card>
            </div>
            <div style={{ display: 'table-cell', width: '25%', padding: '0 8px' }}>
              <Card className="analytics-summary-card">
                <Statistic 
                  title="Total Uploads" 
                  value={metrics.totalUploads} 
                  prefix={<CloudUploadOutlined />} 
                />
              </Card>
            </div>
            <div style={{ display: 'table-cell', width: '25%', padding: '0 8px' }}>
              <Card className="analytics-summary-card">
                <Statistic 
                  title="Avg Processing Time" 
                  value={formatTime(metrics.avgProcessingTime)} 
                  prefix={<ClockCircleOutlined />} 
                />
              </Card>
            </div>
            <div style={{ display: 'table-cell', width: '25%', padding: '0 8px' }}>
              <Card className="analytics-summary-card">
                <Statistic 
                  title="Success Rate" 
                  value={summary?.by_status?.success 
                    ? `${((summary.by_status.success / summary.total) * 100).toFixed(0)}%` 
                    : 'N/A'} 
                  prefix={<CheckCircleOutlined />}
                  valueStyle={{ color: '#3f8600' }}
                />
              </Card>
            </div>
          </div>
          
          {/* Second row with remaining metrics */}
          <div style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}>
            {/* Most active editors */}
            <div style={{ display: 'table-cell', width: '33.33%', padding: '0 8px', verticalAlign: 'top' }}>
              <Card 
                title="Top Editors" 
                className="analytics-summary-card"
                size="small"
              >
                <List
                  size="small"
                  dataSource={topEditors}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar icon={<UserOutlined />} />}
                        title={item.user}
                      />
                      <div>{item.count} edits</div>
                    </List.Item>
                  )}
                />
              </Card>
            </div>
            
            {/* Most edited files */}
            <div style={{ display: 'table-cell', width: '33.33%', padding: '0 8px', verticalAlign: 'top' }}>
              <Card 
                title="Most Edited Files" 
                className="analytics-summary-card"
                size="small"
              >
                <List
                  size="small"
                  dataSource={metrics.popularFiles}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar icon={<FileOutlined />} />}
                        title={
                          <Tooltip title={item.file}>
                            {item.file.length > 30 
                              ? `...${item.file.substring(item.file.length - 30)}` 
                              : item.file}
                          </Tooltip>
                        }
                      />
                      <div>{item.count} edits</div>
                    </List.Item>
                  )}
                />
              </Card>
            </div>
            
            {/* Tool usage */}
            <div style={{ display: 'table-cell', width: '33.33%', padding: '0 8px', verticalAlign: 'top' }}>
              <Card 
                title="Tool Usage" 
                className="analytics-summary-card"
              >
                <div className="chart-container">
                  <ResponsiveContainer width="100%" height={200}>
                    <PieChart>
                      <Pie
                        data={toolData}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {toolData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip formatter={(value) => [value, 'Usage Count']} />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </Card>
    );
  };

  // Helper function to format time
  const formatTimeDisplay = (ms) => {
    if (!ms) return 'N/A';
    if (ms < 1000) return `${ms}ms`;
    if (ms < 60000) return `${(ms / 1000).toFixed(1)}s`;
    return `${(ms / 60000).toFixed(1)}m`;
  };

  // Update the UserProductivityTable component to support drilling down into user metrics
  const UserProductivityTable = ({ logs }) => {
    const [selectedUser, setSelectedUser] = useState(null);
    const [userDetailModalVisible, setUserDetailModalVisible] = useState(false);
    const [userDetailData, setUserDetailData] = useState([]);
    
    const calculateUserMetrics = () => {
      const userMap = {};
      
      logs.forEach(log => {
        const userId = log.user_id || 'unknown';
        const userEmail = log.user_email || 'unknown';
        
        if (!userMap[userId]) {
          userMap[userId] = {
            key: userId,
            name: userEmail,
            operations: 0,
            edits: 0,
            uploads: 0,
            downloads: 0,
            totalTime: 0,
            avgTime: 0,
            successCount: 0,
            failureCount: 0,
            logs: []  // Store individual logs for this user
          };
        }
        
        userMap[userId].operations++;
        userMap[userId].logs.push(log);  // Save log for detailed analysis
        
        // Count by operation
        if (log.operation_type === 'edit') {
          userMap[userId].edits++;
        } else if (log.operation_type === 'upload') {
          userMap[userId].uploads++;
        } else if (log.operation_type === 'download') {
          userMap[userId].downloads++;
        }
        
        // Sum processing time
        if (log.processing_time) {
          userMap[userId].totalTime += log.processing_time;
        }
        
        // Count by status
        if (log.status === 'success') {
          userMap[userId].successCount++;
        } else if (log.status === 'failure') {
          userMap[userId].failureCount++;
        }
      });
      
      // Calculate averages and rates
      Object.values(userMap).forEach(user => {
        user.avgTime = user.operations > 0 ? user.totalTime / user.operations : 0;
        user.successRate = user.operations > 0 
          ? Math.round((user.successCount / user.operations) * 100) 
          : 0;
      });
      
      // Convert to array and sort by total operations
      return Object.values(userMap).sort((a, b) => b.operations - a.operations);
    };
    
    const userMetrics = calculateUserMetrics();
    
    // Function to show detailed metrics for a specific user
    const showUserDetail = (user) => {
      setSelectedUser(user);
      
      // Prepare detailed data for this user (e.g., logs by operation type, by day, etc.)
      prepareUserDetailData(user);
      
      setUserDetailModalVisible(true);
    };
    
    // Prepare detailed data for the selected user
    const prepareUserDetailData = (user) => {
      // 1. Group logs by date
      const logsByDate = {};
      
      user.logs.forEach(log => {
        const date = new Date(log.timestamp).toLocaleDateString();
        if (!logsByDate[date]) {
          logsByDate[date] = [];
        }
        logsByDate[date].push(log);
      });
      
      // 2. Calculate metrics for each date
      const timeSeriesData = Object.keys(logsByDate).map(date => {
        const logsForDay = logsByDate[date];
        const metrics = {
          date,
          totalOperations: logsForDay.length,
          edits: logsForDay.filter(log => log.operation_type === 'edit').length,
          uploads: logsForDay.filter(log => log.operation_type === 'upload').length,
          downloads: logsForDay.filter(log => log.operation_type === 'download').length,
          totalTime: logsForDay.reduce((sum, log) => sum + (log.processing_time || 0), 0),
          avgTime: 0
        };
        
        metrics.avgTime = metrics.totalOperations > 0 ? 
          metrics.totalTime / metrics.totalOperations : 0;
        
        return metrics;
      });
      
      // 3. Sort by date
      timeSeriesData.sort((a, b) => new Date(a.date) - new Date(b.date));
      
      // 4. Calculate metrics by file type
      const fileTypes = {};
      user.logs.forEach(log => {
        if (!log.file_path) return;
        
        const extension = log.file_path.split('.').pop().toLowerCase();
        if (!fileTypes[extension]) {
          fileTypes[extension] = {
            type: extension,
            count: 0,
            totalTime: 0
          };
        }
        
        fileTypes[extension].count++;
        fileTypes[extension].totalTime += (log.processing_time || 0);
      });
      
      const fileTypeData = Object.values(fileTypes).map(type => ({
        ...type,
        avgTime: type.count > 0 ? type.totalTime / type.count : 0
      }));
      
      // 5. Calculate average edit time trend
      const editTimeTrend = timeSeriesData.map(day => ({
        date: day.date,
        avgEditTime: day.edits > 0 ? 
          day.totalTime / day.edits : 0
      }));
      
      // 6. Calculate time efficiency metrics
      const timeEfficiencyData = user.logs
        .filter(log => log.editing_time > 0 && log.operation_type === 'edit')
        .map(log => ({
          date: new Date(log.timestamp).toLocaleDateString(),
          timestamp: log.timestamp,
          file_path: log.file_path,
          processingTime: log.processing_time || 0,
          editingTime: log.editing_time || 0,
          // Calculate efficiency ratio: processing time / editing time
          // A lower ratio means more efficient (less processing time relative to edit time)
          efficiencyRatio: log.processing_time && log.editing_time ? 
            (log.processing_time / log.editing_time) : 0,
          // For visualization, express as a percentage of total time
          processingTimePercent: log.processing_time && log.editing_time ? 
            (log.processing_time / (log.processing_time + log.editing_time)) * 100 : 0,
          editingTimePercent: log.processing_time && log.editing_time ? 
            (log.editing_time / (log.processing_time + log.editing_time)) * 100 : 0
        }))
        .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
      
      // Calculate averages
      const avgEditingTime = user.logs
        .filter(log => log.editing_time > 0)
        .reduce((sum, log) => sum + log.editing_time, 0) / 
        user.logs.filter(log => log.editing_time > 0).length || 0;
      
      setUserDetailData({
        timeSeriesData,
        fileTypeData,
        editTimeTrend,
        timeEfficiencyData,
        avgEditingTime
      });
    };
    
    const columns = [
      {
        title: 'User',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => (
          <Button 
            type="link" 
            onClick={() => showUserDetail(record)}
            style={{ padding: 0, height: 'auto', textAlign: 'left' }}
          >
            {text}
          </Button>
        ),
      },
      {
        title: 'Total Ops',
        dataIndex: 'operations',
        key: 'operations',
        sorter: (a, b) => a.operations - b.operations,
      },
      {
        title: 'Edits',
        dataIndex: 'edits',
        key: 'edits',
        sorter: (a, b) => a.edits - b.edits,
      },
      {
        title: 'Uploads',
        dataIndex: 'uploads',
        key: 'uploads',
        sorter: (a, b) => a.uploads - b.uploads,
      },
      {
        title: 'Total Time',
        key: 'totalTime',
        render: (_, record) => formatTimeDisplay(record.totalTime),
        sorter: (a, b) => a.totalTime - b.totalTime,
      },
      {
        title: 'Avg Time',
        key: 'avgTime',
        render: (_, record) => formatTimeDisplay(record.avgTime),
        sorter: (a, b) => a.avgTime - b.avgTime,
      },
      {
        title: 'Success Rate',
        key: 'successRate',
        render: (_, record) => `${record.successRate}%`,
        sorter: (a, b) => a.successRate - b.successRate,
      },
      {
        title: 'Editing Time',
        key: 'editingTime',
        render: (_, record) => {
          // Calculate average editing time for this user
          const edits = record.logs.filter(log => log.editing_time > 0);
          const avgEditTime = edits.length > 0 ? 
            edits.reduce((sum, log) => sum + log.editing_time, 0) / edits.length : 0;
          
          return formatEditingTime(avgEditTime);
        },
        sorter: (a, b) => {
          const avgA = a.logs.filter(log => log.editing_time > 0).reduce((sum, log) => sum + log.editing_time, 0) / 
            (a.logs.filter(log => log.editing_time > 0).length || 1);
          const avgB = b.logs.filter(log => log.editing_time > 0).reduce((sum, log) => sum + log.editing_time, 0) / 
            (b.logs.filter(log => log.editing_time > 0).length || 1);
          return avgA - avgB;
        },
      },
    ];
    
    return (
      <>
        <Table 
          dataSource={userMetrics} 
          columns={columns} 
          pagination={false}
          scroll={{ x: 'max-content' }}
        />
        
        {/* User Detail Modal */}
        <Modal
          title={`User Activity: ${selectedUser?.name || ''}`}
          open={userDetailModalVisible}
          onCancel={() => setUserDetailModalVisible(false)}
          width={900}
          footer={[
            <Button key="close" onClick={() => setUserDetailModalVisible(false)}>
              Close
            </Button>
          ]}
        >
          {selectedUser && (
            <div>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Card title="User Metrics">
                    <Row gutter={16}>
                      <Col xs={12} sm={8} md={4}>
                        <Statistic
                          title="Total Operations"
                          value={selectedUser.operations}
                        />
                      </Col>
                      <Col xs={12} sm={8} md={4}>
                        <Statistic
                          title="Edit Operations"
                          value={selectedUser.edits}
                        />
                      </Col>
                      <Col xs={12} sm={8} md={4}>
                        <Statistic
                          title="Processing Time"
                          value={formatTimeDisplay(selectedUser.avgTime)}
                        />
                      </Col>
                      <Col xs={12} sm={8} md={4}>
                        <Statistic
                          title="Avg Editing Time"
                          value={formatEditingTime(userDetailData.avgEditingTime || 0)}
                        />
                      </Col>
                      <Col xs={12} sm={8} md={4}>
                        <Statistic
                          title="Success Rate"
                          value={`${selectedUser.successRate}%`}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
                
                <Col span={24}>
                  <Card title="Activity Over Time">
                    <ResponsiveContainer width="100%" height={300} style={{ marginLeft: 0 }}>
                      <ComposedChart data={userDetailData.timeSeriesData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis yAxisId="left" />
                        <YAxis yAxisId="right" orientation="right" />
                        <RechartsTooltip />
                        <Legend />
                        <Bar 
                          yAxisId="left" 
                          dataKey="edits" 
                          name="Edits" 
                          fill={OPERATION_COLORS.edit}
                        />
                        <Bar 
                          yAxisId="left" 
                          dataKey="uploads" 
                          name="Uploads" 
                          fill={OPERATION_COLORS.upload}
                        />
                        <Line 
                          yAxisId="right" 
                          type="monotone" 
                          dataKey="avgTime" 
                          name="Avg Time" 
                          stroke="#ff7300"
                        />
                      </ComposedChart>
                    </ResponsiveContainer>
                  </Card>
                </Col>
                
                <Col xs={24} md={12}>
                  <Card title="File Type Analysis">
                    <ResponsiveContainer width="100%" height={250} style={{ marginLeft: 0 }}>
                      <BarChart data={userDetailData.fileTypeData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="type" />
                        <YAxis />
                        <RechartsTooltip formatter={(value, name) => {
                          if (name === 'avgTime') {
                            return [formatTimeDisplay(value), 'Avg Time'];
                          }
                          return [value, name];
                        }} />
                        <Legend />
                        <Bar dataKey="count" fill="#8884d8" name="Count" />
                        <Bar dataKey="avgTime" fill="#82ca9d" name="Avg Time" />
                      </BarChart>
                    </ResponsiveContainer>
                  </Card>
                </Col>
                
                <Col xs={24} md={12}>
                  <Card title="Edit Time Trend">
                    <ResponsiveContainer width="100%" height={250} style={{ marginLeft: 0 }}>
                      <LineChart data={userDetailData.editTimeTrend}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <RechartsTooltip formatter={(value) => [formatTimeDisplay(value), 'Avg Edit Time']} />
                        <Legend />
                        <Line 
                          type="monotone" 
                          dataKey="avgEditTime" 
                          stroke="#8884d8" 
                          name="Avg Edit Time" 
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </Card>
                </Col>
                
                <Col span={24}>
                  <Card title="Editing vs Processing Time">
                    <Tabs defaultActiveKey="1">
                      <TabPane tab="Timeline" key="1">
                        <ResponsiveContainer width="100%" height={300} style={{ marginLeft: 0 }}>
                          <ComposedChart data={userDetailData.timeEfficiencyData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis 
                              yAxisId="left"
                              label={{ value: 'Time (ms)', angle: -90, position: 'insideLeft' }} 
                            />
                            <YAxis 
                              yAxisId="right" 
                              orientation="right" 
                              label={{ value: 'Ratio', angle: 90, position: 'insideRight' }}
                            />
                            <RechartsTooltip formatter={(value, name) => {
                              if (name === 'editingTime' || name === 'processingTime') {
                                return [formatTimeDisplay(value), name === 'editingTime' ? 'Editing Time' : 'Processing Time'];
                              }
                              if (name === 'efficiencyRatio') {
                                return [`${value.toFixed(4)}`, 'Efficiency Ratio'];
                              }
                              return [value, name];
                            }} />
                            <Legend />
                            <Bar 
                              yAxisId="left" 
                              dataKey="editingTime" 
                              name="Editing Time" 
                              fill="#8884d8" 
                              stackId="time"
                            />
                            <Bar 
                              yAxisId="left" 
                              dataKey="processingTime" 
                              name="Processing Time" 
                              fill="#82ca9d" 
                              stackId="time"
                            />
                            <Line 
                              yAxisId="right" 
                              type="monotone" 
                              dataKey="efficiencyRatio" 
                              name="Efficiency Ratio" 
                              stroke="#ff7300"
                              dot={true}
                            />
                          </ComposedChart>
                        </ResponsiveContainer>
                      </TabPane>
                      <TabPane tab="Time Proportion" key="2">
                        <ResponsiveContainer width="100%" height={300} style={{ marginLeft: 0 }}>
                          <BarChart data={userDetailData.timeEfficiencyData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis label={{ value: 'Percentage (%)', angle: -90, position: 'insideLeft' }} />
                            <RechartsTooltip formatter={(value, name) => {
                              if (name === 'editingTimePercent') {
                                return [`${value.toFixed(1)}%`, 'Editing Time'];
                              }
                              if (name === 'processingTimePercent') {
                                return [`${value.toFixed(1)}%`, 'Processing Time'];
                              }
                              return [value, name];
                            }} />
                            <Legend />
                            <Bar 
                              dataKey="editingTimePercent" 
                              name="Editing Time %" 
                              fill="#8884d8" 
                              stackId="percent"
                            />
                            <Bar 
                              dataKey="processingTimePercent" 
                              name="Processing Time %" 
                              fill="#82ca9d" 
                              stackId="percent"
                            />
                          </BarChart>
                        </ResponsiveContainer>
                      </TabPane>
                    </Tabs>
                  </Card>
                </Col>
                
                <Col span={24}>
                  <Card title="Recent Operations">
                    <Table 
                      dataSource={selectedUser.logs.slice(0, 10).map((log, index) => ({
                        ...log,
                        key: index
                      }))} 
                      columns={[
                        {
                          title: 'Date',
                          key: 'timestamp',
                          render: (_, record) => formatTime(record.timestamp),
                          width: 150,
                        },
                        {
                          title: 'Operation',
                          dataIndex: 'operation_type',
                          key: 'operation_type',
                          width: 80,
                        },
                        {
                          title: 'File Path',
                          dataIndex: 'file_path',
                          key: 'file_path',
                          ellipsis: true,
                        },
                        {
                          title: 'Processing Time',
                          key: 'processing_time',
                          render: (_, record) => formatTimeDisplay(record.processing_time),
                          width: 120,
                        },
                        {
                          title: 'Editing Time',
                          key: 'editing_time',
                          render: (_, record) => formatEditingTime(record.editing_time),
                          width: 120,
                        },
                        {
                          title: 'Status',
                          dataIndex: 'status',
                          key: 'status',
                          width: 80,
                          render: status => (
                            <Tag color={status === 'success' ? 'green' : 'red'}>
                              {typeof status === 'string' ? status.toUpperCase() : 'UNKNOWN'}
                            </Tag>
                          ),
                        },
                      ]}
                      pagination={false}
                      size="small"
                      scroll={{ x: 'max-content' }}
                    />
                  </Card>
                </Col>
              </Row>
            </div>
          )}
        </Modal>
      </>
    );
  };

  // Modify just the DailyProductivityChart component with a simpler approach
  const DailyProductivityChart = ({ logs }) => {
    // Calculate metrics by date
    const calculateDailyMetrics = () => {
      const dateMap = {};
      
      logs.forEach(log => {
        const dateStr = moment(log.timestamp).format('YYYY-MM-DD');
        
        if (!dateMap[dateStr]) {
          dateMap[dateStr] = {
            date: dateStr,
            totalOps: 0,
            edits: 0,
            uploads: 0,
            downloads: 0,
            successCount: 0,
            failureCount: 0,
            totalTime: 0,
            avgTime: 0
          };
        }
        
        dateMap[dateStr].totalOps++;
        
        // Count by operation type
        if (log.operation_type === 'edit') {
          dateMap[dateStr].edits++;
        } else if (log.operation_type === 'upload') {
          dateMap[dateStr].uploads++;
        } else if (log.operation_type === 'download') {
          dateMap[dateStr].downloads++;
        }
        
        // Count by status
        if (log.status === 'success') {
          dateMap[dateStr].successCount++;
        } else if (log.status === 'failure') {
          dateMap[dateStr].failureCount++;
        }
        
        // Sum processing time
        if (log.processing_time) {
          dateMap[dateStr].totalTime += log.processing_time;
        }
      });
      
      // Calculate averages
      Object.values(dateMap).forEach(day => {
        day.avgTime = day.totalOps > 0 ? day.totalTime / day.totalOps : 0;
        day.successRate = day.totalOps > 0 
          ? Math.round((day.successCount / day.totalOps) * 100) 
          : 0;
      });
      
      return Object.values(dateMap)
        .sort((a, b) => moment(a.date).diff(moment(b.date)));
    };
    
    const dailyMetrics = calculateDailyMetrics();
    
    return (
      <div className="chart-container">
        <ResponsiveContainer width="100%" height={400}>
          <ComposedChart 
            data={dailyMetrics}
            margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis yAxisId="left" />
            <YAxis yAxisId="right" orientation="right" />
            <RechartsTooltip />
            <Legend />
            <Bar 
              yAxisId="left" 
              dataKey="totalOps" 
              name="Total Operations" 
              fill="#8884d8" 
            />
            <Bar 
              yAxisId="left" 
              dataKey="edits" 
              name="Edits" 
              fill={OPERATION_COLORS.edit} 
              stackId="operations" 
              hide
            />
            <Bar 
              yAxisId="left" 
              dataKey="uploads" 
              name="Uploads" 
              fill={OPERATION_COLORS.upload} 
              stackId="operations" 
              hide
            />
            <Bar 
              yAxisId="left" 
              dataKey="downloads" 
              name="Downloads" 
              fill={OPERATION_COLORS.download} 
              stackId="operations" 
              hide
            />
            <Line 
              yAxisId="right" 
              type="monotone" 
              dataKey="avgTime" 
              name="Avg Processing Time" 
              stroke="#ff7300"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  };

  // Refactor ToolProductivityChart to avoid flexbox
  const ToolProductivityChart = ({ logs }) => {
    // Calculate metrics by tool
    const calculateToolMetrics = () => {
      const toolMap = {};
      
      logs.forEach(log => {
        const tool = log.metadata?.toolUsed || 'Unknown';
        
        if (!toolMap[tool]) {
          toolMap[tool] = {
            name: tool,
            count: 0,
            totalTime: 0,
            avgTime: 0,
            successCount: 0,
            failureCount: 0
          };
        }
        
        toolMap[tool].count++;
        
        // Sum processing time
        if (log.processing_time) {
          toolMap[tool].totalTime += log.processing_time;
        }
        
        // Count by status
        if (log.status === 'success') {
          toolMap[tool].successCount++;
        } else if (log.status === 'failure') {
          toolMap[tool].failureCount++;
        }
      });
      
      // Calculate averages
      Object.values(toolMap).forEach(tool => {
        tool.avgTime = tool.count > 0 ? tool.totalTime / tool.count : 0;
        tool.successRate = tool.count > 0 
          ? Math.round((tool.successCount / tool.count) * 100) 
          : 0;
      });
      
      return Object.values(toolMap);
    };
    
    const toolMetrics = calculateToolMetrics();
    
    return (
      <div className="chart-container">
        <Card title="Tool Usage Comparison" style={{ marginBottom: '16px' }}>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={toolMetrics}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <RechartsTooltip />
              <Legend />
              <Bar dataKey="count" fill="#8884d8" name="Operations" />
              <Bar dataKey="successRate" fill="#82ca9d" name="Success Rate (%)" />
            </BarChart>
          </ResponsiveContainer>
        </Card>
        
        <Card title="Tool Processing Time">
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={toolMetrics}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <RechartsTooltip formatter={(value, name) => {
                if (name === 'avgTime' || name === 'totalTime') {
                  return [formatTimeDisplay(value), name === 'avgTime' ? 'Avg Time' : 'Total Time'];
                }
                return [value, name];
              }} />
              <Legend />
              <Bar dataKey="avgTime" fill="#8884d8" name="avgTime" />
              <Bar dataKey="totalTime" fill="#82ca9d" name="totalTime" />
            </BarChart>
          </ResponsiveContainer>
        </Card>
      </div>
    );
  };

  // Add an AccessDeniedComponent to display when user doesn't have permission
  const AccessDeniedComponent = () => (
    <div style={{ textAlign: 'center', padding: '50px 20px' }}>
      <Alert
        message="Access Denied"
        description={
          <div>
            <p>{permissionError || 'You do not have permission to view logs. This feature is restricted to administrators.'}</p>
            <p>If you believe you should have access, please contact your administrator.</p>
          </div>
        }
        type="error"
        showIcon
        style={{ maxWidth: '600px', margin: '0 auto' }}
      />
    </div>
  );

  // Function to show log detail modal
  const showLogDetail = (record) => {
    setSelectedLog(record);
    setDetailModalVisible(true);
  };

  // Function to render the log entries tab content
  const renderLogEntriesTab = () => {
    return (
      <>
        <Card style={{ marginBottom: 16 }}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Row gutter={16}>
              <Col span={8}>
                <Space>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <input
                      type="date"
                      style={{
                        padding: '4px 8px',
                        border: '1px solid #d9d9d9',
                        borderRadius: '2px',
                        width: '160px',
                        height: '32px'
                      }}
                      value={formFilters.from_date}
                      max={moment().format('YYYY-MM-DD')}
                      onChange={(e) => {
                        const newDate = e.target.value;
                        if (newDate && moment(newDate).isValid()) {
                          setFormFilters(prev => ({
                            ...prev,
                            from_date: newDate
                          }));
                        }
                      }}
                    />
                    <span>to</span>
                    <input
                      type="date"
                      style={{
                        padding: '4px 8px',
                        border: '1px solid #d9d9d9',
                        borderRadius: '2px',
                        width: '160px',
                        height: '32px'
                      }}
                      value={formFilters.to_date}
                      max={moment().format('YYYY-MM-DD')}
                      onChange={(e) => {
                        const newDate = e.target.value;
                        if (newDate && moment(newDate).isValid()) {
                          setFormFilters(prev => ({
                            ...prev,
                            to_date: newDate
                          }));
                        }
                      }}
                    />
                  </div>
                </Space>
              </Col>
              <Col span={8}>
                <Select
                  placeholder="Filter by operation"
                  style={{ width: '100%' }}
                  value={formFilters.operation_type}
                  onChange={(value) => handleFormChange('operation_type', value)}
                >
                  <Option value="">All Operations</Option>
                  <Option value="edit">Edit</Option>
                  <Option value="upload">Upload</Option>
                  <Option value="download">Download</Option>
                  <Option value="delete">Delete</Option>
                  <Option value="lock">Lock</Option>
                  <Option value="unlock">Unlock</Option>
                </Select>
              </Col>
              <Col span={8}>
                <Select
                  placeholder="Filter by status"
                  style={{ width: '100%' }}
                  value={formFilters.status}
                  onChange={(value) => handleFormChange('status', value)}
                >
                  <Option value="">All Statuses</Option>
                  <Option value="success">Success</Option>
                  <Option value="failure">Failure</Option>
                </Select>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={16}>
                <Input
                  placeholder="Filter by file path"
                  value={formFilters.file_path}
                  onChange={(e) => handleFormChange('file_path', e.target.value)}
                />
              </Col>
              <Col span={8}>
                <Space>
                  <Button type="primary" onClick={applyFilters}>Apply Filters</Button>
                  <Button onClick={resetFilters}>Reset</Button>
                  <Button icon={<DownloadOutlined />} onClick={downloadCSV}>
                    Export CSV
                  </Button>
                  <Button 
                    icon={<ReloadOutlined />} 
                    onClick={() => {
                      fetchLogs(1, pagination.pageSize);
                      fetchSummary();
                    }}
                  >
                    Refresh
                  </Button>
                </Space>
              </Col>
            </Row>
          </Space>
        </Card>
        
        <Table
          dataSource={logs}
          columns={columns}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100'],
            showTotal: (total) => `Total ${total} items`,
            onChange: (page, pageSize) => {
              console.log("Direct pagination change:", page, pageSize);
              setPagination(prev => ({
                ...prev,
                current: page,
                pageSize: pageSize
              }));
              fetchLogs(page, pageSize);
              // Important: Stop event propagation
              if (window.event) {
                window.event.stopPropagation();
              }
            }
          }}
          onChange={handleTableChange}
          scroll={{ x: 'max-content' }}
          loading={loading}
          onRow={(record) => ({
            onClick: (e) => {
              // Make sure the click is not from a button or other interactive element
              if (e.target.tagName.toLowerCase() !== 'button' && 
                  !e.target.closest('button') && 
                  !e.target.closest('a')) {
                e.stopPropagation();
                showLogDetail(record);
              }
            },
            style: { cursor: 'pointer' }
          })}
        />
        
        {/* Log Detail Modal */}
        <Modal
          title="Log Entry Details"
          open={detailModalVisible}
          onCancel={() => setDetailModalVisible(false)}
          width={800}
          footer={[
            <Button key="close" onClick={() => setDetailModalVisible(false)}>
              Close
            </Button>
          ]}
          style={{ top: 20 }}
          modalRender={modal => (
            <div style={{ zIndex: 2000 }}>
              {modal}
            </div>
          )}
        >
          {selectedLog && (
            <div className="log-detail">
              <List
                itemLayout="horizontal"
                dataSource={[
                  {
                    label: 'Timestamp',
                    value: formatTime(selectedLog.timestamp)
                  },
                  {
                    label: 'User',
                    value: (
                      <div>
                        <div>{selectedLog.user_name || 'Unknown'}</div>
                        <div style={{ color: '#666' }}>{selectedLog.user_email || 'Unknown email'}</div>
                      </div>
                    )
                  },
                  {
                    label: 'Operation',
                    value: (
                      <Tag color={OPERATION_COLORS[selectedLog.operation_type] || '#1890ff'}>
                        {typeof selectedLog.operation_type === 'string' ? selectedLog.operation_type.toUpperCase() : 'UNKNOWN'}
                      </Tag>
                    )
                  },
                  {
                    label: 'File',
                    value: (
                      <div>
                        <div>{selectedLog.file_name || 'Unknown'}</div>
                        <div style={{ color: '#666', wordBreak: 'break-all' }}>{selectedLog.file_path}</div>
                      </div>
                    )
                  },
                  {
                    label: 'File Size',
                    value: selectedLog.file_size ? 
                      (selectedLog.file_size < 1024 ? 
                        `${selectedLog.file_size} bytes` : 
                        (selectedLog.file_size < 1024 * 1024 ? 
                          `${(selectedLog.file_size / 1024).toFixed(2)} KB` : 
                          `${(selectedLog.file_size / (1024 * 1024)).toFixed(2)} MB`)) : 
                      'N/A'
                  },
                  {
                    label: 'Status',
                    value: (
                      <Tag color={selectedLog.status === 'success' ? '#52c41a' : '#f5222d'}>
                        {typeof selectedLog.status === 'string' ? selectedLog.status.toUpperCase() : 'UNKNOWN'}
                      </Tag>
                    )
                  },
                  {
                    label: 'Processing Time',
                    value: selectedLog.processing_time ? 
                      (selectedLog.processing_time < 1000 ? 
                        `${selectedLog.processing_time}ms` : 
                        (selectedLog.processing_time < 60000 ? 
                          `${(selectedLog.processing_time / 1000).toFixed(1)}s` : 
                          `${(selectedLog.processing_time / 60000).toFixed(1)}m`)) : 
                      'N/A'
                  },
                  {
                    label: 'Tool Used',
                    value: selectedLog.metadata?.toolUsed || 'Unknown'
                  },
                  {
                    label: 'Changes Summary',
                    value: selectedLog.changes_summary ? (
                      <div>
                        {selectedLog.changes_summary.linesAdded > 0 && (
                          <div>Lines Added: <Tag color="green">+{selectedLog.changes_summary.linesAdded}</Tag></div>
                        )}
                        {selectedLog.changes_summary.linesRemoved > 0 && (
                          <div>Lines Removed: <Tag color="red">-{selectedLog.changes_summary.linesRemoved}</Tag></div>
                        )}
                        {selectedLog.changes_summary.charactersChanged && (
                          <div>Characters Changed: {selectedLog.changes_summary.charactersChanged}</div>
                        )}
                      </div>
                    ) : 'N/A'
                  },
                  {
                    label: 'Editing Time',
                    value: formatEditingTime(selectedLog.editing_time)
                  },
                  {
                    label: 'Additional Details',
                    value: selectedLog.metadata ? (
                      <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                        <pre>{JSON.stringify(selectedLog.metadata, null, 2)}</pre>
                      </div>
                    ) : 'N/A'
                  }
                ]}
                renderItem={item => (
                  <List.Item key={item.label}>
                    <List.Item.Meta
                      title={item.label}
                      description={item.value}
                    />
                  </List.Item>
                )}
              />
            </div>
          )}
        </Modal>
      </>
    );
  };

  return (
    <div className="logs-page">
      {loading && <Spin size="large" className="page-loader" />}
      
      {/* Show error if permissions have been checked and user is not admin */}
      {permissionsChecked && !isAdmin ? (
        <AccessDeniedComponent />
      ) : (
        <div className="logs-content">
          <Card 
            title={<Title level={4}>File Activity Logs</Title>}
            className="logs-card"
          >
            <Tabs 
              activeKey={activeTab} 
              onChange={handleTabChange}
              destroyInactiveTabPane={true}
              type="card"
              style={{ zIndex: 5 }}
              items={[
                {
                  key: "1",
                  label: (
                    <span>
                      <PieChartOutlined />
                      Dashboard
                    </span>
                  ),
                  children: <Dashboard logs={logs} summary={summary} />
                },
                {
                  key: "2",
                  label: (
                    <span>
                      <FileTextOutlined />
                      Log Entries
                    </span>
                  ),
                  children: renderLogEntriesTab()
                }
              ]}
            />
          </Card>
        </div>
      )}
    </div>
  );
};

export default LogsPage; 